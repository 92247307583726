import { apiSlice } from "app/api/apiSlice";

export const worksApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getWorks: builder.query({
            query: () => ({
                url: `Works/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Works", id: "LIST" }],
        }),
        getWorkTypes: builder.query({
            query: () => ({
                url: `WorkTypes/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
        }),
        getAllWorksWithouWorkPlanning: builder.query({
            query: () => ({
                url: `Works`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "WorksWithoutWorkPlanning", id: "LIST" }],
        }),
        getAllWorksWichEmployeeHasProcessedSalaryLastMonth: builder.query({
            query: (id) => ({
                url: `Works/GetAllWorksWichEmployeeHasProcessedSalaryLastMonth/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "WorksWichEmployeeHasProcessedSalaryLastMonth",
                    id: "LIST",
                },
            ],
        }),
        getAllWorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById:
            builder.query({
                query: (id) => ({
                    url: `Works/GetAllWorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById/${id}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                }),
                providesTags: [
                    {
                        type: "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
                        id: "LIST",
                    },
                ],
            }),
        getWorkExtraCostsByWorkId: builder.query({
            query: (id) => ({
                url: `WorkExtraCosts/GetAllWorkExtraCostsByWorkId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "WorkExtraCostsByWorkId",
                    id: "LIST",
                },
            ],
        }),
        getWorkExtraCostById: builder.query({
            query: (id) => ({
                url: `WorkExtraCosts/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "WorkExtraCostById",
                    id: "LIST",
                },
            ],
        }),
        postWorkExtraCost: builder.mutation({
            query: (workExtraCostData) => ({
                url: `WorkExtraCosts`,
                method: "POST",
                body: {
                    ...workExtraCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkExtraCostsByWorkId", id: "LIST" },
                { type: "WorkById", id: "BYID" },
            ],
        }),
        updateWorkExtraCost: builder.mutation({
            query: (workExtraCostData) => ({
                url: `WorkExtraCosts`,
                method: "PUT",
                body: {
                    ...workExtraCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkExtraCostsByWorkId", id: "LIST" },
                { type: "WorkById", id: "BYID" },
            ],
        }),
        deleteWorkExtraCostById: builder.mutation({
            query: (id) => ({
                url: `WorkExtraCosts/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "WorkExtraCostsByWorkId", id: "LIST" },
                { type: "WorkById", id: "BYID" },
            ],
        }),
        getAccomodationPlanningsByWorkId: builder.query({
            query: (id) => ({
                url: `AccomodationPlannings/GetAccomodationPlanningsByWorkId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "AccomodationPlanningsByWorkId",
                    id: "LIST",
                },
            ],
        }),
        getEmployeePlanningsByWorkId: builder.query({
            query: (id) => ({
                url: `EmployeePlannings/GetEmployeePlanningsByWorkId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "EmployeePlanningsByWorkId",
                    id: "LIST",
                },
            ],
        }),
        getVehiclePlanningsByWorkId: builder.query({
            query: (id) => ({
                url: `VehiclePlannings/GetVehiclePlanningsByWorkId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                {
                    type: "VehiclePlanningsByWorkId",
                    id: "LIST",
                },
            ],
        }),
        postWork: builder.mutation({
            query: (workData) => ({
                url: `Works`,
                method: "POST",
                body: {
                    ...workData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: "LIST" },
                { type: "Clients", id: result.clientId },
                { type: "WorksWithoutWorkPlanning", id: "LIST" },
            ],
        }),
        getWorkById: builder.query({
            query: (id) => ({
                url: `Works/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "Works", id: arg },
                { type: "WorkById", id: "BYID" },
            ],
        }),
        updateWork: builder.mutation({
            query: (workData) => ({
                url: `Works`,
                method: "PUT",
                body: {
                    ...workData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: "LIST" },
                { type: "Works", id: arg.id },
                { type: "Clients", id: arg.clientId },
                { type: "WorkPlannings", id: "LIST" },
                { type: "VehicleById", id: "BYID" },
                { type: "EmployeeById", id: "BYID" },
                { type: "AccomodationById", id: "BYID" },
                { type: "WorkPlanningById", id: "BYID" },
                {
                    type: "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
                    id: "LIST",
                },
                { type: "WorksWithoutWorkPlanning", id: "LIST" },
            ],
        }),
        addWorkContact: builder.mutation({
            query: (workData) => ({
                url: `Works/AddWorkContact`,
                method: "PUT",
                body: {
                    ...workData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        editWorkContact: builder.mutation({
            query: (workData) => ({
                url: `Works/UpdateWorkContact`,
                method: "PUT",
                body: {
                    ...workData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        deleteWorkContact: builder.mutation({
            query: (workData) => ({
                url: `Works/DeleteWorkContact`,
                method: "PUT",
                body: {
                    ...workData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: arg.id },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        deleteWorkById: builder.mutation({
            query: (id) => ({
                url: `Works/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Works", id: "LIST" },
                { type: "Clients", id: result.clientId },
                { type: "Contacts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetWorksQuery,
    useLazyGetWorkExtraCostByIdQuery,
    useGetWorkExtraCostsByWorkIdQuery,
    usePostWorkExtraCostMutation,
    useUpdateWorkExtraCostMutation,
    useDeleteWorkExtraCostByIdMutation,
    usePostWorkMutation,
    useGetWorkTypesQuery,
    useGetWorkByIdQuery,
    useGetAccomodationPlanningsByWorkIdQuery,
    useGetEmployeePlanningsByWorkIdQuery,
    useGetVehiclePlanningsByWorkIdQuery,
    useUpdateWorkMutation,
    useAddWorkContactMutation,
    useDeleteWorkContactMutation,
    useDeleteWorkByIdMutation,
    useEditWorkContactMutation,
    useGetAllWorksWithouWorkPlanningQuery,
    useGetAllWorksWithouWorkPlanningAndWithActualWorkInWorkPlanningByIdQuery,
    useGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery,
    useLazyGetAllWorksWichEmployeeHasProcessedSalaryLastMonthQuery,
} = worksApiSlice;
