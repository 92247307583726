import React from "react";
import { Search } from "@mui/icons-material";
import {
    IconButton,
    TextField,
    InputAdornment,
    Button,
    useTheme,
} from "@mui/material";
import {
    GridToolbarDensitySelector,
    GridToolbarContainer,
    GridToolbarExport,
    GridToolbarFilterButton,
    GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import FlexBetween from "./FlexBetween";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const DataGridCustomToolbar = ({ entity }) => {
    const { palette } = useTheme(); /* Set the Theme Pallet */
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();

    const handleNavigation = () => {
        navigate(`${location.pathname}/new`);
    };

    return (
        <GridToolbarContainer
            sx={{
                "& .MuiButtonBase-root:hover": {
                    backgroundColor: `${palette.primary.light} !important`,
                },
            }}
        >
            <FlexBetween width="100%" padding="0.5rem 0">
                <FlexBetween>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <GridToolbarDensitySelector />
                    <GridToolbarExport />
                </FlexBetween>
                <Button
                    type="button"
                    onClick={handleNavigation}
                    sx={{
                        p: "0.5rem 1rem",
                        backgroundColor: palette.primary.main,
                        color: `${palette.neutral.white} !important`,
                        "&:hover": { color: palette.neutral.white },
                    }}
                >
                    {t("add") + ` ${entity.toUpperCase()}`}
                </Button>
            </FlexBetween>
        </GridToolbarContainer>
    );
};

export default DataGridCustomToolbar;
