export const RESPONSES = {
    login: {
        success: 1000,
        invalidCredentials: 1001,
        invalidPassword: 1002,
        newPasswordSent: 1003,
        incorrectEmail: 1004,
        invalidToken: 1005,
        invalidLoginCode: 1006,
    },
    user: {
        invalidUser: 2000,
        successToInsertUser: 2001,
        invalidUserToUpdate: 2002,
        successToUpdateUser: 2003,
        successToDeleteUserById: 2004,
        invalidToDeleteUserById: 2005,
        invalidToUpdatePassword: 2006,
        sucessToUpdatePassword: 2007,
        sucessToLogout: 2008,
    },
    userType: {
        invalidUserType: 3000,
    },
    client: {
        invalidToInsertClient: 4000,
        successToInsertClient: 4001,
        successToUpdateClient: 4002,
        invalidToUpdateClient: 4003,
        invalidToDeleteClientById: 4004,
        invalidToDeleteClientByIdBecauseWorks: 4005,
        successToDeleteClientById: 4006,
        invalidToInsertClientBecauseSameContact: 4007,
        invalidToUpdateClientBecauseSameContact: 4008,
        sucessToAddAttachments: 4009,
    },
    accommodation: {
        invalidAccomodation: 5000,
        successToInsertAccomodation: 5001,
        invalidToUpdateAccomodation: 5002,
        successToUpdateAccomodation: 5003,
        invalidToDeleteAccomodation: 5004,
        invalidToDeleteAccomodationBecauseAssignedToWorkPlanning: 5005,
        successToDeleteAccomodation: 5006,
        unsucessToAddCostToAccomodation: 5007,
        sucessToAddCostToAccomodation: 5008,
        sucessToDeleteAccomodationCost: 5009,
        invalidToDeleteAccomodationCost: 5010,
    },
    attachment: {
        successToInsertAttachment: 6000,
        successToDeleteAttachment: 6001,
        invalidToDeleteAttachment: 6002,
    },
    supplier: {
        invalidSupplier: 7000,
        successToInsertSupplier: 7001,
        invalidToUpdateSupplier: 7002,
        successToUpdateSupplier: 7003,
        invalidToDeleteSupplier: 7004,
        invalidToDeleteSupplierBecauseWorks: 7005,
        successToDeleteSupplier: 7006,
        invalidToAddSupplierContact: 7007,
        sucessToAddSupplierContact: 7008,
        invalidToDeleteSupplierContact: 7009,
        sucessToDeleteSupplierContact: 7010,
        invalidContact: 7011,
        sucessToUpdateSupplierContact: 7012,
        invalidToUpdateSupplierContact: 7013,
    },
    employeeCategory: {
        invalidEmployeeCategory: 8000,
        successToInsertEmployeeCategory: 8001,
        invalidToUpdateEmployeeCategory: 8002,
        successToUpdateEmployeeCategory: 8003,
        invalidToDeleteEmployeeCategory: 8004,
        successToDeleteEmployeeCategory: 8005,
        invalidToDeleteEmployeeCategoryBecauseIsAssignedToEmployees: 8006,
    },
    employee: {
        invalidEmployee: 9000,
        successToInsertEmployee: 9001,
        invalidToUpdateEmployee: 9002,
        successToUpdateEmployee: 9003,
        invalidToDeleteEmployee: 9004,
        invalidToDeleteEmployeeBecauseAssignedToWorkPlanning: 9005,
        successToDeleteEmployee: 9006,
        invalidToUpdateEmployeeBecauseSickLeave: 9007,
    },
    vehicle: {
        invalidVehicle: 10000,
        sucessToInsertVehicle: 10001,
        invalidToUpdateVehicle: 10002,
        sucessToUpdateVehicle: 10003,
        invalidToDeleteVehicle: 10004,
        invalidToDeleteVehicleBecauseAssignedToWorkPlanning: 10005,
        sucessToDeleteVehicle: 10006,
    },
    work: {
        invalidWork: 11000,
        sucessToInsertWork: 11001,
        invalidToUpdateWork: 11002,
        sucessToUpdateWork: 11003,
        invalidToDeleteWork: 11004,
        sucessToDeleteWork: 11005,
        invalidToDeleteWorkBecauseAssignedToWorkPlanning: 11006,
        invalidToAddWorkContact: 11007,
        sucessToAddWorkContact: 11008,
        invalidToDeleteWorkContact: 11009,
        sucessToDeleteWorkContact: 11010,
        sucessToUpdateWorkContact: 11011,
        invalidToUpdateWorkContact: 11012,
    },
    companyDoc: {
        invalidCompanyDoc: 12000,
        sucessToInsertCompanyDoc: 12001,
        invalidToUpdateCompanyDoc: 12002,
        sucessToUpdateCompanyDoc: 12003,
        invalidToDeleteCompanyDoc: 12004,
        sucessToDeleteCompanyDoc: 12005,
    },
    costCategory: {
        invalidCostCategory: 13000,
        sucessToInsertCostCategory: 13001,
        invalidToUpdateCostCategory: 13002,
        sucessToUpdateCostCategory: 13003,
        invalidToDeleteCostCategory: 13004,
        sucessToDeleteCostCategory: 13005,
    },
    accomodationCost: {
        invalidToUpdateAccomodationCost: 14000,
        sucessToUpdateAccomodationCost: 14001,
    },
    workPlanning: {
        invalidToInsertWorkPlanning: 15000,
        sucessToInsertWorkPlanning: 15001,
        invalidWorkPlanning: 15002,
        accomodationInOtherWorkPlannings: 15003,
        vehicleInOtherWorkPlannings: 15004,
        employeeInOtherWorkPlannings: 15005,
        sucessToRemoveAccomodationFromWorkPlanning: 15006,
        invalidToRemoveAccomodationFromWorkPlanning: 15007,
        sucessToRemoveEmployeeFromWorkPlanning: 15008,
        invalidToRemoveEmployeeFromWorkPlanning: 15009,
        sucessToRemoveVehicleFromWorkPlanning: 15010,
        invalidToRemoveVehicleFromWorkPlanning: 15011,
        sucessToUpdateWorkPlanning: 15012,
        sucessToRemoveWorkPlanning: 15013,
        haveAccomodationVehiclesOrEmployeesInOtherWorkPlanning: 15014,
        invalidToRemoveAccomodationPlanningBecauseAssignedToEmployeePlanning: 15015,
        invalidToRemoveVehiclePlanningBecauseAssignedToEmployeePlanning: 15016,
    },
    accomodationPlanning: {
        sucessToUpdateAccomodationPlanning: 16000,
        invalidToUpdateAccomodationPlanning: 16001,
        invalidToUpdateAccomodationPlanningBecauseAssignedToEmployeePlanning: 16002,
    },
    employeePlanning: {
        sucessToUpdateEmployeePlanning: 17000,
        invalidToUpdateEmployeePlanning: 17001,
        sucessToAddVehicle: 17002,
        sucessToAddAccomodation: 17003,
        sucessToUpdateVehicleFromEmployeePlanning: 17004,
        sucessToUpdateAccomodationFromEmployeePlanning: 17005,
        sucessToDeleteVehicleFromEmployeePlanning: 17006,
        sucessToDeleteAccomodationFromEmployeePlanning: 17007,
    },
    vehiclePlanning: {
        sucessToUpdateVehiclePlanning: 18000,
        invalidToUpdateVehiclePlanning: 18001,
    },
    advancePayment: {
        sucessToInsertAdvancePayment: 19000,
        sucessToUpdateAdvancePayment: 19001,
        invalidToUpdateAdvancePayment: 19002,
        sucessToDeleteAdvancePayment: 19003,
        invalidToDeleteAdvancePayment: 19004,
    },
    employeeWorkHourCosts: {
        invalidToInsertEmployeeWorkHourCosts: 20000,
        sucessToInsertEmployeeWorkHourCosts: 20001,
        sucessToAddWorkHourCosts: 20002,
        invalidToAddWorkHourCosts: 20003,
        invalidToUpdateEmployeeWorkHourCosts: 20004,
        sucessToUpdateEmployeeWorkHourCosts: 20005,
        sucessToRemoveEmployeeWorkHourCosts: 20006,
        invalidToRemoveEmployeeWorkHourCosts: 20007,
        invalidToProcessingBecauseHaventProcessings: 20008,
        invalidToProcessingBecauseSomeFuncHaventSalary: 20009,
        sucessToGenerateProcessingPDF: 20010,
        invalidToUpdateBecauseDetails: 20011,
        sucessToProcessPDFRevision: 20012,
        invalidToProcessPDF: 20013,
        invalidToProcessPDFRevision: 20014,
        sucessToAddWorkHour_Employee_FixedSalaryToWorkHourCost: 20015,
    },
    workHourCost: {
        invalidToRemoveWorkHourCost: 21000,
        sucessToRemoveWorkHourCost: 21001,
        sucessToUpdateWorkHourCost: 21002,
        invalidToUpdateWorkHourCost: 21003,
    },
    adjustment: {
        sucessToInsertAdjustment: 22000,
        sucessToUpdateAdjustment: 22001,
        invalidToUpdateAdjustment: 22002,
        sucessToDeleteAdjustment: 22003,
        invalidToDeleteAdjustment: 22004,
    },
    workHours_Employee_FixedSalary: {
        sucessToUpdateWorkHours_Employee_FixedSalary: 23000,
        sucessToDeleteWorkHours_Employee_FixedSalary: 23001,
    },
    employeeCostsByWork: {
        sucessToInsertEmployeeCostsByWork: 24000,
        invalidToInsertEmployeeCostsByWork: 24001,
        sucessToCalculateEmployeeRegist: 24002,
        sucessToCalculateSalarySheet: 24003,
        sucessToCalculateCompanyDiscountsFromWork: 24004,
        sucessToCalculateAllCost: 24007,
        sucessToDeleteEmployeeCostByWork: 24008,
        sucessToGenerateGraphs: 24009,
        sucessToRecallCosts: 24011,
    },
    reminder: {
        sucessToInsertReminder: 25000,
        sucessToUpdateReminder: 25001,
        sucessToDeleteReminder: 25002,
    },
    subContractor: {
        sucessToInsertSubContractor: 26000,
        invalidSubContractor: 26001,
        sucessToUpdateSubContractor: 26002,
        invalidToUpdateSubContractor: 26003,
        invalidToDeleteSubContractor: 26004,
        sucessToDeleteSubContractor: 26005,
        invalidToDeleteSubContractorBeacauseHaveAssignedEmployees: 26006,
    },
    equipment: {
        invalidEquipement: 28000,
        sucessToInsertEquipment: 28001,
        invalidToUpdateEquipment: 28002,
        sucessToUpdateEquipment: 28003,
        invalidToDeleteEquipment: 28004,
        sucessToDeleteEquipment: 28005,
    },
    application: {
        sucessToInsertApplication: 29000,
        invalidToInsertApplication: 29001,
        sucessToUpdateApplication: 29002,
        invalidToUpdateApplication: 29003,
        sucessToDeleteApplication: 29004,
        invalidToDeleteApplication: 29005,
    },
    workExtraCost: {
        sucessToInsertWorkExtraCost: 30000,
        sucessToUpdateWorkExtraCost: 30001,
        sucessToDeleteWorkExtraCost: 30002,
    },
};
