import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetAllEmployeeCostsByWorkHistoryQuery } from "./employeeCostsByWorkApiSlice";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import CustomTable from "components/CustomTable";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const EmployeeCostsByWorkListHistory = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const columns = useRef([
        { title: "Id", field: "id", hidden: true },
        {
            field: "employee.nInternal",
            title: t("nInternal"),
            filtering: true,
        },
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "workPlanning.work.name",
            title: t("planningWork"),
            filtering: true,
        },
        {
            field: "employeeCostMonth",
            title: t("monthlyEmployeeCost"),
            filtering: true,
            render: (rowData) => {
                const employeeCostMonth = rowData.employeeCostMonth;
                return employeeCostMonth
                    ? dayjs(employeeCostMonth).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const columnsMobile = useRef([
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "employeeCostMonth",
            title: t("monthlyEmployeeCost"),
            filtering: true,
            render: (rowData) => {
                const employeeCostMonth = rowData.employeeCostMonth;
                return employeeCostMonth
                    ? dayjs(employeeCostMonth).format("YYYY-MM-DD")
                    : "";
            },
        },
    ]);

    const tableRef = useRef(null);
    const handleGetEmployeeCostsByWork = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const isHistoric = true;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? columns.current[orderBy.orderBy].field
                              : columnsMobile.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;
            const response = await dispatch(
                apiSlice.endpoints.getEmployeeCostsByWorkPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                    isHistoric,
                })
            ).unwrap();
            return {
                data: response.employeeCostsByWork,
                page: query.page,
                totalCount: response.totalEmployeeCostsByWork || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    return (
        <Box m="1.5rem 2.5rem">
            <>
                <PageHeader
                    title={t("employeeCostByWorkHistory")}
                    // subtitle={t("costsList")}
                />
                <Box
                    mt="40px"
                    minHeight="70vh"
                    display="flex"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            flexGrow: 1,
                            width: "100%",
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.A,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.background.I}`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                            display: !isNonMobile ? "flex" : undefined,
                            flexDirection: !isNonMobile ? "row" : undefined,
                        },
                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                            {
                                backgroundColor: `${theme.palette.background.A} !important`,
                            },
                    }}
                >
                    <CustomTable
                        tableRef={tableRef}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        title={t("employeeCostHistory")}
                        columns={
                            isNonMobile
                                ? columns.current
                                : columnsMobile.current
                        }
                        getData={handleGetEmployeeCostsByWork}
                        style={{
                            width: "100%",
                            padding: "0 10px 0 10px",
                            margin: "0% 2% 1% 2%",
                            overflow: !isNonMobile ? "auto" : null,
                        }}
                        actions={[
                            {
                                icon: () => <Edit />,
                                tooltip: "Editar",
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    navigate(
                                        `${location.pathname}/${rowData.id}`
                                    );
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        onRefresh={() =>
                            tableRef.current && tableRef.current.onQueryChange()
                        }
                    />
                </Box>
            </>
        </Box>
    );
};

export default EmployeeCostsByWorkListHistory;
