import { apiSlice } from "app/api/apiSlice";

export const companyDocsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCompanyDocs: builder.query({
            query: () => ({
                url: `CompanyDocs/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CompanyDocs", id: "LIST" }],
        }),
        postCompanyDoc: builder.mutation({
            query: (companyDocData) => ({
                url: `CompanyDocs`,
                method: "POST",
                body: {
                    ...companyDocData,
                },
            }),
            invalidatesTags: [{ type: "CompanyDocs", id: "LIST" }],
        }),
        getCompanyDocById: builder.query({
            query: (id) => ({
                url: `CompanyDocs/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "CompanyDocs", id: arg },
            ],
        }),
        updateCompanyDoc: builder.mutation({
            query: (companyDocData) => ({
                url: `CompanyDocs`,
                method: "PUT",
                body: {
                    ...companyDocData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "CompanyDocs", id: "LIST" },
                { type: "CompanyDocs", id: arg.id },
            ],
        }),
        deleteCompanyDocById: builder.mutation({
            query: (id) => ({
                url: `CompanyDocs/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "CompanyDocs", id: "LIST" }],
        }),
    }),
});

export const {
    useGetCompanyDocsQuery,
    usePostCompanyDocMutation,
    useGetCompanyDocByIdQuery,
    useUpdateCompanyDocMutation,
    useDeleteCompanyDocByIdMutation,
} = companyDocsApiSlice;
