import { apiSlice } from "app/api/apiSlice";

export const costCategoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCostCategories: builder.query({
            query: () => ({
                url: `CostCategories/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CostCategories", id: "LIST" }],
        }),
        getCostCategoryCategories: builder.query({
            query: () => ({
                url: `CostCategoryCategories`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CostCategoryCategories", id: "LIST" }],
        }),
        getCostCategoryFirstTypes: builder.query({
            query: () => ({
                url: `CostCategoryFirstTypes`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CostCategoryFirstTypes", id: "LIST" }],
        }),
        getCostCategorySecondTypes: builder.query({
            query: () => ({
                url: `CostCategorySecondTypes`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CostCategorySecondTypes", id: "LIST" }],
        }),
        postCostCategory: builder.mutation({
            query: (costCategoryData) => ({
                url: `CostCategories`,
                method: "POST",
                body: {
                    ...costCategoryData,
                },
            }),
            invalidatesTags: [{ type: "CostCategories", id: "LIST" }],
        }),
        getCostCategoryById: builder.query({
            query: (id) => ({
                url: `CostCategories/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "CostCategories", id: arg },
            ],
        }),
        updateCostCategory: builder.mutation({
            query: (costCategoryData) => ({
                url: `CostCategories`,
                method: "PUT",
                body: {
                    ...costCategoryData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "CostCategories", id: "LIST" },
                { type: "CostCategories", id: arg.id },
            ],
        }),
        deleteCostCategoryById: builder.mutation({
            query: (id) => ({
                url: `CostCategories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "CostCategories", id: "LIST" }],
        }),
    }),
});

export const {
    useGetCostCategoriesQuery,
    useGetCostCategoryCategoriesQuery,
    useGetCostCategoryFirstTypesQuery,
    useGetCostCategorySecondTypesQuery,
    usePostCostCategoryMutation,
    useGetCostCategoryByIdQuery,
    useUpdateCostCategoryMutation,
    useDeleteCostCategoryByIdMutation,
} = costCategoriesApiSlice;
