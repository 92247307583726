import React, { useState } from "react";
import { Box, Button, useTheme, Alert, useMediaQuery } from "@mui/material";
import { useGetUsersQuery, useDeleteUserByIdMutation } from "./usersApiSlice";
import Snackbar from "@mui/material/Snackbar";
import PageHeader from "components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import { RESPONSES } from "config/responses";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingSpinner from "components/LoadingSpinner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { LoadingButton } from "@mui/lab";

const UsersList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const user = useSelector(selectCurrentUser);

    const { data: users, isLoading } = useGetUsersQuery();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: 1,
        },
        {
            field: "address",
            headerName: t("address"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
        {
            field: "username",
            headerName: t("username"),
            flex: 1,
        },
        {
            field: "userType",
            headerName: t("userType"),
            flex: 1,
            valueGetter: (params) => params.row.userType.type,
        },
        {
            field: "actions",
            headerName: t("remove"),
            flex: 1,
            renderCell: (row) => (
                <DeleteIcon
                    onClick={() =>
                        handleDeleteAccomodationCostOpenDialog(row.id)
                    }
                />
            ),
        },
    ];

    const columnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "email",
            headerName: t("email"),
            flex: 1,
        },
    ];

    const [deleteUser, { isLoading: isLoadingDeleteUser }] =
        useDeleteUserByIdMutation(); /* Set the Delete cost accomodation Mutation */

    const [
        openDeleteAccomodationCostDialog,
        setOpenDeleteAccomodationCostDialog,
    ] = useState(false);

    const handleOpenDeleteAccomodationCostDialog = () => {
        setOpenDeleteAccomodationCostDialog(true);
    };

    const handleCloseDeleteAccomodationCostDialog = () => {
        setOpenDeleteAccomodationCostDialog(false);
    };

    const [idToDelete, setIdToDelete] = useState(null);
    const handleDeleteAccomodationCostOpenDialog = async (id) => {
        setIdToDelete(id);
        setOpenDeleteAccomodationCostDialog(true);
    };
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleDeleteUser = async () => {
        try {
            if (user.id === idToDelete) {
                handleSnackbarOpen(
                    t("invalidToDeleteUserBecauseIsCurrentUser"),
                    "error"
                );
            } else {
                const { codeResult } = await deleteUser(idToDelete).unwrap();

                if (codeResult !== RESPONSES.user.successToDeleteUserById) {
                    if (codeResult !== RESPONSES.user.invalidToDeleteUserById) {
                        handleSnackbarOpen(
                            t("invalidToDeleteUserById"),
                            "error"
                        );
                    } else {
                        handleSnackbarOpen(t("errorDeleteUser"), "error");
                    }
                } else {
                    setOpenDeleteAccomodationCostDialog(false);
                    handleSnackbarOpen(t("successToDeleteUserById"), "success");
                }
            }
        } catch (err) {
            handleSnackbarOpen(t("errorDeleteUser"), "error");
        }
    };

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoading || !users ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader title={t("users")} subtitle={t("userList")} />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <DataGrid
                            autoHeight
                            loading={isLoading || !users}
                            getRowId={(row) => row.id}
                            rows={users || []}
                            responsive
                            columns={!isNonMobile ? columnsMobile : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            slots={{ toolbar: DataGridCustomToolbar }}
                            slotProps={{
                                toolbar: { entity: t("newUser") },
                            }}
                            disableRowSelectionOnClick
                        />
                    </Box>
                    <Dialog
                        open={openDeleteAccomodationCostDialog}
                        onClose={handleCloseDeleteAccomodationCostDialog}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {t("deleteUserConfirmationTitle")}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t("deleteUserConfirmationMessage")}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.alt.red,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.alt.lightRed,
                                    },
                                }}
                                onClick={
                                    handleCloseDeleteAccomodationCostDialog
                                }
                            >
                                {t("noMessage")}
                            </LoadingButton>
                            <LoadingButton
                                sx={{
                                    backgroundColor: theme.palette.primary.main,
                                    color: theme.palette.neutral.white,
                                    "&:hover": {
                                        backgroundColor:
                                            theme.palette.primary.light,
                                    },
                                }}
                                // isLoading={isLoadingDeleteUser}
                                onClick={handleDeleteUser}
                                autoFocus
                            >
                                {t("yesMessage")}
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>
                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                </>
            )}
        </Box>
    );
};

export default UsersList;
