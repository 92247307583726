import React, { useCallback, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { useNavigate, useLocation } from "react-router-dom";
import { t } from "i18next";
import { Add, Edit } from "@mui/icons-material";
import { apiSlice } from "app/api/apiSlice";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import CustomTable from "components/CustomTable";

const PAGE_SIZE_OPTIONS = [10, 20, 50];

const AdvancePaymentsList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const dispatch = useDispatch();

    const columns = useRef([
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "advancePaymentDate",
            title: t("advancePaymentDate"),
            filtering: true,
            render: (rowData) => {
                const advancePaymentDate = rowData.advancePaymentDate;
                return advancePaymentDate
                    ? dayjs(advancePaymentDate).format("YYYY-MM-DD")
                    : "";
            },
        },
        {
            field: "value",
            title: t("advancePaymentValue"),
            filtering: true,
            render: (rowData) => {
                return rowData.value + " €";
            },
        },
    ]);

    const columnsMobile = useRef([
        {
            field: "employee.name",
            title: t("employeeName"),
            filtering: true,
        },
        {
            field: "advancePaymentDate",
            title: t("advancePaymentDate"),
            filtering: true,
            render: (rowData) => {
                const advancePaymentDate = rowData.advancePaymentDate;
                return advancePaymentDate
                    ? dayjs(advancePaymentDate).format("YYYY-MM-DD")
                    : "";
            },
        },
        {
            field: "value",
            title: t("advancePaymentValue"),
            filtering: true,
            render: (rowData) => {
                return rowData.advancePaymentValue + " €";
            },
        },
    ]);

    const tableRef = useRef(null);
    const handleGetAdvancePayments = useCallback(
        async (query) => {
            const limit = query.pageSize;
            const offset = query.page * query.pageSize;
            const search = query.search || null;
            const filters = query.filters
                ? JSON.stringify(
                      query.filters.map((filter) => ({
                          column: filter.column.field,
                          operator: filter.operator,
                          value: filter.value,
                      }))
                  )
                : null;
            const orderByCollection = query.orderByCollection
                ? JSON.stringify(
                      query.orderByCollection.map((orderBy) => ({
                          column: isNonMobile
                              ? columns.current[orderBy.orderBy].field
                              : columnsMobile.current[orderBy.orderBy].field,
                          orderDirection: orderBy.orderDirection,
                      }))
                  )
                : null;

            const response = await dispatch(
                apiSlice.endpoints.getAdvancePaymentsPaginated.initiate({
                    limit: limit || 10,
                    offset: offset || 0,
                    search,
                    filters,
                    orderByCollection,
                })
            ).unwrap();
            return {
                data: response.advancePayments,
                page: query.page,
                totalCount: response.totalAdvancePayments || 0,
            };
        },
        [isNonMobile ? columns : columnsMobile]
    );

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            <>
                <PageHeader
                    title={t("advancePayments")}
                    // subtitle={t("advancePaymentsList")}
                />
                <Box
                    mt="40px"
                    minHeight="70vh"
                    display="flex"
                    sx={{
                        "& .MuiDataGrid-root": {
                            border: "none",
                            flexGrow: 1,
                            width: "100%",
                        },
                        "& .MuiDataGrid-cell": {
                            // borderBottom: "none",
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderBottom: "none",
                        },
                        "& .MuiDataGrid-virtualScroller": {
                            backgroundColor: theme.palette.background.A,
                        },
                        "& .MuiDataGrid-footerContainer": {
                            backgroundColor: `${theme.palette.background.J} !important`,
                            color: theme.palette.secondary[100],
                            borderTop: "none",
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                            color: `${theme.palette.background.I}`,
                        },
                        "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                            display: !isNonMobile ? "flex" : undefined,
                            flexDirection: !isNonMobile ? "column" : undefined,
                        },
                        "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                            {
                                backgroundColor: `${theme.palette.background.A} !important`,
                            },
                    }}
                >
                    <CustomTable
                        tableRef={tableRef}
                        title={t("adjustmentsList")}
                        pageSizeOptions={PAGE_SIZE_OPTIONS}
                        columns={
                            isNonMobile
                                ? columns.current
                                : columnsMobile.current
                        }
                        getData={handleGetAdvancePayments}
                        style={{
                            width: "100%",
                            padding: "0 10px 0 10px",
                            margin: "0 2% 1% 2%",
                            overflow: !isNonMobile ? "auto" : null,
                        }}
                        actions={[
                            {
                                icon: () => <Add />,
                                tooltip: "Adicionar",
                                isFreeAction: true,
                                onClick: () => {
                                    navigate(`${location.pathname}/new`);
                                    return Promise.resolve();
                                },
                            },
                            {
                                icon: () => <Edit />,
                                tooltip: "Editar",
                                isFreeAction: false,
                                onClick: (event, rowData) => {
                                    navigate(
                                        `${location.pathname}/${rowData.id}`
                                    );
                                    return Promise.resolve();
                                },
                            },
                        ]}
                        onRefresh={() =>
                            tableRef.current && tableRef.current.onQueryChange()
                        }
                    />
                </Box>
            </>
        </Box>
    );
};

export default AdvancePaymentsList;
