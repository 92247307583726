import { apiSlice } from "app/api/apiSlice";

export const employeeCategoriesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllEmployeeCategories: builder.query({
            query: () => ({
                url: `EmployeeCategories/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
        postEmployeeCategory: builder.mutation({
            query: (employeeCategoryData) => ({
                url: `EmployeeCategories`,
                method: "POST",
                body: {
                    ...employeeCategoryData,
                },
            }),
            invalidatesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
        getEmployeeCategoryById: builder.query({
            query: (id) => ({
                url: `EmployeeCategories/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeeCategoryId", id: arg },
            ],
        }),
        updateEmployeeCategory: builder.mutation({
            query: (employeeCategoryData) => ({
                url: `EmployeeCategories`,
                method: "PUT",
                body: {
                    ...employeeCategoryData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "EmployeeCategories", id: "LIST" },
                { type: "EmployeeCategoryId", id: arg.id },
                { type: "EmployeeById", id: "BYID" },
                { type: "Employees", id: "LIST" },
            ],
        }),
        deleteEmployeeCategoryById: builder.mutation({
            query: (id) => ({
                url: `EmployeeCategories/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "EmployeeCategories", id: "LIST" }],
        }),
    }),
});

export const {
    useGetAllEmployeeCategoriesQuery,
    usePostEmployeeCategoryMutation,
    useGetEmployeeCategoryByIdQuery,
    useUpdateEmployeeCategoryMutation,
    useDeleteEmployeeCategoryByIdMutation,
} = employeeCategoriesApiSlice;
