import { apiSlice } from "app/api/apiSlice";
import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";

export const employeesApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployees: builder.query({
            query: () => ({
                url: `Employees/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Employees", id: "LIST" }],
        }),
        getNotOfficeEmployees: builder.query({
            query: () => ({
                url: `Employees/GetNotOfficeEmployees`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "NotOfficeEmployees", id: "LIST" }],
        }),
        getEmployeesNotInWorkPlanning: builder.query({
            query: (id) => ({
                url: `Employees/GetEmployeesNotInWorkPlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeesNotInWorkPlanning", id: arg },
                { type: "EmployeesNotInWorkPlanning", id: "LIST" },
            ],
        }),
        getEmployeesWithoutEmployeeWorkHourCost: builder.query({
            query: () => ({
                url: `Employees/GetEmployeesWithoutEmployeeWorkHourCost`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
            ],
        }),
        getEmployeesWithoutEmployeeCostByWork: builder.query({
            query: () => ({
                url: `Employees/GetEmployeesWithoutEmployeeCostByWork`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
            ],
        }),
        getEmployeePlanningsByEmployeeId: builder.query({
            query: (Id) => ({
                url: `EmployeePlannings/GetEmployeePlanningsByEmployeeId/${Id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmployeePlanningsByEmployeeId", id: "LIST" },
            ],
        }),
        postEmployee: builder.mutation({
            query: (employeeData) => ({
                url: `Employees`,
                method: "POST",
                body: {
                    ...employeeData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Employees", id: "LIST" },
                { type: "EmployeesNotInWorkPlanning", id: "LIST" },
                { type: "SubContractors", id: arg.subContractorId },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
        getSalaryTypes: builder.query({
            query: () => ({
                url: `SalaryTypes`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "SalaryTypes", id: "LIST" }],
        }),
        getEmployeeById: builder.query({
            query: (id) => ({
                url: `Employees/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "Employees", id: arg },
                { type: "EmployeeById", id: "BYID" },
            ],
        }),
        updateEmployee: builder.mutation({
            query: (employeeData) => ({
                url: `Employees`,
                method: "PUT",
                body: {
                    ...employeeData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Employees", id: "LIST" },
                { type: "Employees", id: arg.id },
                { type: "WorkPlanningById", id: "BYID" },
                { type: "SubContractors", id: arg.subContractorId },
                { type: "SubContractors", id: result.changedSubcontractorId },
                { type: "Alerts", id: "LIST" },
                { type: "EmployeesNotInWorkPlanning", id: "LIST" },
            ],
        }),
        deleteEmployeeById: builder.mutation({
            query: (id) => ({
                url: `Employees/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Employees", id: "LIST" },
                { type: "SubContractors", id: "BYID" },
                { type: "SubContractors", id: "LIST" },
                { type: "EmployeesWithoutEmployeeCostByWork", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                { type: "EmployeesNotInWorkPlanning", id: "LIST" },
                { type: "Alerts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetEmployeesQuery,
    usePostEmployeeMutation,
    useGetSalaryTypesQuery,
    useGetEmployeePlanningsByEmployeeIdQuery,
    useGetNotOfficeEmployeesQuery,
    useGetEmployeeByIdQuery,
    useUpdateEmployeeMutation,
    useDeleteEmployeeByIdMutation,
    useGetEmployeesNotInWorkPlanningQuery,
    useGetEmployeesWithoutEmployeeWorkHourCostQuery,
    useGetEmployeesWithoutEmployeeCostByWorkQuery,
} = employeesApiSlice;
