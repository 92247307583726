import { apiSlice } from "app/api/apiSlice";

export const accomodationsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAccomodations: builder.query({
            query: () => ({
                url: `Accomodations/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Accomodations", id: "LIST" }],
        }),
        getAccomodationsNotInWorkPlanning: builder.query({
            query: (id) => ({
                url: `Accomodations/GetAccomodationsNotInWorkPlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "AccomodationsNotInWorkPlanning", id: "LIST" },
                { type: "AccomodationsNotInWorkPlanning", id: arg },
            ],
        }),
        getAccomodationsInWorkPlanning: builder.query({
            query: (id) => ({
                url: `Accomodations/GetAccomodationsInWorkPlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "AccomodationsInWorkPlanning", id: arg },
            ],
        }),
        getAccomodationPlanningsByAccomodationId: builder.query({
            query: (id) => ({
                url: `AccomodationPlannings/GetAccomodationPlanningsByAccomodationId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "AccomodationPlanningsByAccomodationId", id: arg },
            ],
        }),
        getEmployeePlanningsByAccomodationId: builder.query({
            query: (id) => ({
                url: `EmployeePlannings/GetEmployeePlanningsByAccomodationId/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeePlanningsByAccomodationId", id: arg },
            ],
        }),
        getAccomodationsInWorkPlanningAndNotInEmployeePlanning: builder.query({
            query: (id) => ({
                url: `Accomodations/GetAccomodationsInWorkPlanningAndNotInEmployeePlanning/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                {
                    type: "AccomodationsInWorkPlanningAndNotInEmployeePlanning",
                    id: arg.split("-")[1],
                },
            ],
        }),
        postAccomodation: builder.mutation({
            query: (accomodationData) => ({
                url: `Accomodations`,
                method: "POST",
                body: {
                    ...accomodationData,
                },
            }),
            invalidatesTags: [
                { type: "Accomodations", id: "LIST" },
                { type: "AccomodationsNotInWorkPlanning", id: "LIST" },
                { type: "Contacts", id: "LIST" },
            ],
        }),
        getAccomodationById: builder.query({
            query: (id) => ({
                url: `Accomodations/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "Accomodations", id: arg },
                { type: "AccomodationById", id: "BYID" },
            ],
        }),
        updateAccomodation: builder.mutation({
            query: (accomodationData) => ({
                url: `Accomodations`,
                method: "PUT",
                body: {
                    ...accomodationData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Accomodations", id: "LIST" },
                { type: "Accomodations", id: arg.id },
                { type: "Contacts", id: "LIST" },
                { type: "WorkPlanningById", id: "BYID" },
            ],
        }),
        deleteAccomodationById: builder.mutation({
            query: (id) => ({
                url: `Accomodations/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "Accomodations", id: "LIST" },
                { type: "Contacts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetAccomodationsQuery,
    useGetEmployeePlanningsByAccomodationIdQuery,
    usePostAccomodationMutation,
    useGetAccomodationByIdQuery,
    useGetAccomodationPlanningsByAccomodationIdQuery,
    useUpdateAccomodationMutation,
    useDeleteAccomodationByIdMutation,
    useLazyGetAccomodationsInWorkPlanningAndNotInEmployeePlanningQuery,
    useGetAccomodationsNotInWorkPlanningQuery,
    useGetAccomodationsInWorkPlanningQuery,
} = accomodationsApiSlice;
