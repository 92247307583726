import { apiSlice } from "app/api/apiSlice";

export const suppliersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getSubContractors: builder.query({
            query: () => ({
                url: `SubContractors`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "SubContractors", id: "LIST" }],
        }),
        postSubContractor: builder.mutation({
            query: (subContractorData) => ({
                url: `SubContractors`,
                method: "POST",
                body: {
                    ...subContractorData,
                },
            }),
            invalidatesTags: [{ type: "SubContractors", id: "LIST" }],
        }),
        getSubContractorById: builder.query({
            query: (id) => ({
                url: `SubContractors/${id}`,
            }),
            providesTags: (result, error, arg) => [
                { type: "SubContractors", id: arg },
                { type: "SubContractors", id: "BYID" },
            ],
        }),
        updateSubContractor: builder.mutation({
            query: (subContractorData) => ({
                url: `SubContractors`,
                method: "PUT",
                body: {
                    ...subContractorData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "SubContractors", id: "LIST" },
                { type: "SubContractors", id: arg.id },
            ],
        }),
        deleteSubContractorById: builder.mutation({
            query: (id) => ({
                url: `SubContractors/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "SubContractors", id: "LIST" }],
        }),
    }),
});

export const {
    useGetSubContractorByIdQuery,
    useGetSubContractorsQuery,
    usePostSubContractorMutation,
    useUpdateSubContractorMutation,
    useDeleteSubContractorByIdMutation,
} = suppliersApiSlice;
