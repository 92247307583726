import { apiSlice } from "app/api/apiSlice";

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query({
            query: () => ({
                url: `Users/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Users", id: "LIST" }],
        }),
        getUserById: builder.query({
            query: (id) => ({
                url: `Users/${id}`,
            }),
            providesTags: (result, error, arg) => [{ type: "Users", id: arg }],
        }),
        getUserTypes: builder.query({
            query: () => ({
                url: `UserTypes`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "UserTypes", id: "LIST" }],
        }),
        postUser: builder.mutation({
            query: (userData) => ({
                url: `Users`,
                method: "POST",
                body: {
                    ...userData,
                },
            }),
            invalidatesTags: [{ type: "Users", id: "LIST" }],
        }),
        updateUser: builder.mutation({
            query: (userData) => ({
                url: `Users`,
                method: "PUT",
                body: {
                    ...userData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Users", id: "LIST" },
                { type: "Users", id: arg.id },
            ],
        }),
        updatePassword: builder.mutation({
            query: (userData) => ({
                url: `Users/ChangePassword`,
                method: "PUT",
                body: {
                    ...userData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "Users", id: arg.id },
            ],
        }),
        deleteUserById: builder.mutation({
            query: (id) => ({
                url: `Users/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: "Users", id: "LIST" }],
        }),
    }),
});

export const {
    useGetUsersQuery,
    useGetUserByIdQuery,
    useGetUserTypesQuery,
    usePostUserMutation,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useDeleteUserByIdMutation,
} = usersApiSlice;
