import React from "react";
import { useEffect } from "react";
import {
    Box,
    TextField,
    useMediaQuery,
    Typography,
    FormControl,
    Autocomplete,
    Alert,
} from "@mui/material";
import LineChart from "components/charts/LineChart";
import BarChart from "components/charts/BarChart";
import PieChart from "components/charts/PieChart";
import CalendarChart from "components/charts/CalendarChart";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import {
    useGetAllCompanyWorkResumesByMonthResumeQuery,
    useGetAllPrivateWorkResumesByMonthResumeQuery,
    useGetCostCategoriesResumeByDateQuery,
    useGetMonthResumesByYearQuery,
    useLazyGetAllCompanyWorkResumesByMonthResumeQuery,
    useLazyGetAllPrivateWorkResumesByMonthResumeQuery,
    useLazyGetAllWorkResumesByClientIdAndMonthResumeQuery,
    useLazyGetCostCategoriesResumeByDateQuery,
    useLazyGetEmpoyeeCostsBySucontractorIdAndDateQuery,
    useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery,
} from "./homePageApiSlice";
import { subMonths } from "date-fns";
import LoadingSpinner from "components/LoadingSpinner";
import {
    useGetAllCompanyWorkPlanningsQuery,
    useGetAllPrivateWorkPlanningsQuery,
} from "features/workPlanning/workPlanningsApiSlice";
import { useGetSubContractorsQuery } from "features/subContractors/subContractorsApiSlice";
import LineChartCostCategories from "components/charts/LineChartCostCategories";
import LineChartSubContractor from "components/charts/LineChartSubContractor";
import { useGetClientsQuery } from "features/clients/clientsApiSlice";

const HomePage = () => {
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */
    const { t } = useTranslation();

    const lineData = [
        {
            id: "Custo",
            color: "hsl(121, 60%, 23%)",
            data: [
                {
                    x: "Continente Boa Esperança Planeamento 4 noite",
                    y: 1233,
                },
                {
                    x: "Obra 3",
                    y: 2321,
                },
                {
                    x: "Obra 5",
                    y: 4321,
                },
                {
                    x: "Obra 19",
                    y: 2341,
                },
                {
                    x: "Obra 6",
                    y: 4532,
                },
                {
                    x: "Obra 7",
                    y: 1222,
                },
                {
                    x: "car",
                    y: 1987,
                },
                {
                    x: "moto",
                    y: 3425,
                },
                {
                    x: "bicycle",
                    y: 3258,
                },
                {
                    x: "horse",
                    y: 2356,
                },
                {
                    x: "Obra 44",
                    y: 2321,
                },
                {
                    x: "Obra 45",
                    y: 4321,
                },
                {
                    x: "Obra 46",
                    y: 2341,
                },
                {
                    x: "Obra 47",
                    y: 4532,
                },
                {
                    x: "Obra 48",
                    y: 1222,
                },
            ],
        },
        {
            id: "Lucro",
            color: "hsl(265, 70%, 50%)",
            data: [
                {
                    x: "Continente Boa Esperança Planeamento 4 noite",
                    y: 5678,
                },
                {
                    x: "Obra 3",
                    y: 5677,
                },
                {
                    x: "Obra 5",
                    y: 5674,
                },
                {
                    x: "Obra 19",
                    y: 7876,
                },
                {
                    x: "Obra 6",
                    y: 9876,
                },
                {
                    x: "Obra 7",
                    y: 7869,
                },
                {
                    x: "car",
                    y: 5463,
                },
                {
                    x: "moto",
                    y: 6236,
                },
                {
                    x: "bicycle",
                    y: 7543,
                },
                {
                    x: "horse",
                    y: 9876,
                },
                {
                    x: "Obra 44",
                    y: 5677,
                },
                {
                    x: "Obra 45",
                    y: 5674,
                },
                {
                    x: "Obra 46",
                    y: 7876,
                },
                {
                    x: "Obra 47",
                    y: 9876,
                },
                {
                    x: "Obra 48",
                    y: 7869,
                },
            ],
        },
        {
            id: "Horas",
            color: "hsl(265, 70%, 50%)",
            data: [
                {
                    x: "Continente Boa Esperança Planeamento 4 noite",
                    y: 456,
                },
                {
                    x: "Obra 3",
                    y: 564,
                },
                {
                    x: "Obra 5",
                    y: 654,
                },
                {
                    x: "Obra 19",
                    y: 765,
                },
                {
                    x: "Obra 6",
                    y: 432,
                },
                {
                    x: "Obra 7",
                    y: 346,
                },
                {
                    x: "car",
                    y: 578,
                },
                {
                    x: "moto",
                    y: 678,
                },
                {
                    x: "bicycle",
                    y: 876,
                },
                {
                    x: "horse",
                    y: 456,
                },
                {
                    x: "Obra 44",
                    y: 564,
                },
                {
                    x: "Obra 45",
                    y: 654,
                },
                {
                    x: "Obra 46",
                    y: 765,
                },
                {
                    x: "Obra 47",
                    y: 432,
                },
                {
                    x: "Obra 48",
                    y: 346,
                },
            ],
        },
    ];

    // const barData = [

    //     {
    //         country: "AD",
    //         "hot dog": 200,
    //         "hot dogColor": "hsl(281, 70%, 50%)",
    //         burger: 68,
    //         burgerColor: "hsl(267, 70%, 50%)",
    //         sandwich: 105,
    //         sandwichColor: "hsl(181, 70%, 50%)",
    //         kebab: 64,
    //         kebabColor: "hsl(229, 70%, 50%)",
    //         fries: 19,
    //         friesColor: "hsl(20, 70%, 50%)",
    //         donut: 166,
    //         donutColor: "hsl(243, 70%, 50%)",
    //     },
    //     {
    //         country: "AE",
    //         "hot dog": 156,
    //         "hot dogColor": "hsl(143, 70%, 50%)",
    //         burger: 43,
    //         burgerColor: "hsl(307, 70%, 50%)",
    //         sandwich: 75,
    //         sandwichColor: "hsl(202, 70%, 50%)",
    //         kebab: 0,
    //         kebabColor: "hsl(237, 70%, 50%)",
    //         fries: 160,
    //         friesColor: "hsl(353, 70%, 50%)",
    //         donut: 179,
    //         donutColor: "hsl(66, 70%, 50%)",
    //     },
    //     {
    //         country: "AF",
    //         "hot dog": 123,
    //         "hot dogColor": "hsl(128, 70%, 50%)",
    //         burger: 197,
    //         burgerColor: "hsl(176, 70%, 50%)",
    //         sandwich: 64,
    //         sandwichColor: "hsl(295, 70%, 50%)",
    //         kebab: 154,
    //         kebabColor: "hsl(262, 70%, 50%)",
    //         fries: 94,
    //         friesColor: "hsl(211, 70%, 50%)",
    //         donut: 122,
    //         donutColor: "hsl(153, 70%, 50%)",
    //     },
    //     {
    //         country: "AG",
    //         "hot dog": 98,
    //         "hot dogColor": "hsl(258, 70%, 50%)",
    //         burger: 189,
    //         burgerColor: "hsl(172, 70%, 50%)",
    //         sandwich: 131,
    //         sandwichColor: "hsl(71, 70%, 50%)",
    //         kebab: 4,
    //         kebabColor: "hsl(335, 70%, 50%)",
    //         fries: 126,
    //         friesColor: "hsl(308, 70%, 50%)",
    //         donut: 31,
    //         donutColor: "hsl(2, 70%, 50%)",
    //     },
    //     {
    //         country: "AI",
    //         "hot dog": 80,
    //         "hot dogColor": "hsl(83, 70%, 50%)",
    //         burger: 10,
    //         burgerColor: "hsl(355, 70%, 50%)",
    //         sandwich: 185,
    //         sandwichColor: "hsl(38, 70%, 50%)",
    //         kebab: 87,
    //         kebabColor: "hsl(37, 70%, 50%)",
    //         fries: 103,
    //         friesColor: "hsl(160, 70%, 50%)",
    //         donut: 55,
    //         donutColor: "hsl(108, 70%, 50%)",
    //     },
    //     {
    //         country: "AL",
    //         "hot dog": 106,
    //         "hot dogColor": "hsl(115, 70%, 50%)",
    //         burger: 61,
    //         burgerColor: "hsl(1, 70%, 50%)",
    //         sandwich: 112,
    //         sandwichColor: "hsl(323, 70%, 50%)",
    //         kebab: 158,
    //         kebabColor: "hsl(331, 70%, 50%)",
    //         fries: 190,
    //         friesColor: "hsl(99, 70%, 50%)",
    //         donut: 125,
    //         donutColor: "hsl(311, 70%, 50%)",
    //     },
    //     {
    //         country: "AM",
    //         "hot dog": 196,
    //         "hot dogColor": "hsl(194, 70%, 50%)",
    //         burger: 48,
    //         burgerColor: "hsl(58, 70%, 50%)",
    //         sandwich: 24,
    //         sandwichColor: "hsl(132, 70%, 50%)",
    //         kebab: 34,
    //         kebabColor: "hsl(233, 70%, 50%)",
    //         fries: 94,
    //         friesColor: "hsl(95, 70%, 50%)",
    //         donut: 197,
    //         donutColor: "hsl(254, 70%, 50%)",
    //     },
    // ];

    // const pieData = [
    //     {
    //         id: "haskell",
    //         label: "haskell",
    //         value: 587,
    //         color: "hsl(27, 70%, 50%)",
    //     },
    //     {
    //         id: "php",
    //         label: "php",
    //         value: 506,
    //         color: "hsl(85, 70%, 50%)",
    //     },
    //     {
    //         id: "rust",
    //         label: "rust",
    //         value: 425,
    //         color: "hsl(2, 70%, 50%)",
    //     },
    //     {
    //         id: "go",
    //         label: "go",
    //         value: 271,
    //         color: "hsl(28, 70%, 50%)",
    //     },
    //     {
    //         id: "hack",
    //         label: "hack",
    //         value: 131,
    //         color: "hsl(198, 70%, 50%)",
    //     },
    // ];

    // const calendarData = [
    //     {
    //         value: 199,
    //         day: "2017-02-28",
    //     },
    //     {
    //         value: 288,
    //         day: "2017-03-07",
    //     },
    //     {
    //         value: 279,
    //         day: "2017-01-07",
    //     },
    //     {
    //         value: 254,
    //         day: "2016-01-25",
    //     },
    //     {
    //         value: 80,
    //         day: "2017-12-08",
    //     },
    //     {
    //         value: 269,
    //         day: "2015-09-10",
    //     },
    //     {
    //         value: 397,
    //         day: "2015-06-26",
    //     },
    //     {
    //         value: 360,
    //         day: "2016-03-13",
    //     },
    //     {
    //         value: 157,
    //         day: "2015-04-29",
    //     },
    //     {
    //         value: 164,
    //         day: "2016-06-03",
    //     },
    //     {
    //         value: 99,
    //         day: "2016-01-22",
    //     },
    //     {
    //         value: 10,
    //         day: "2017-12-20",
    //     },
    //     {
    //         value: 173,
    //         day: "2018-05-04",
    //     },
    //     {
    //         value: 171,
    //         day: "2017-09-29",
    //     },
    //     {
    //         value: 50,
    //         day: "2016-05-28",
    //     },
    //     {
    //         value: 168,
    //         day: "2016-08-01",
    //     },
    //     {
    //         value: 317,
    //         day: "2015-10-02",
    //     },
    //     {
    //         value: 198,
    //         day: "2017-03-24",
    //     },
    //     {
    //         value: 150,
    //         day: "2016-07-07",
    //     },
    //     {
    //         value: 302,
    //         day: "2016-04-20",
    //     },
    //     {
    //         value: 243,
    //         day: "2017-03-06",
    //     },
    //     {
    //         value: 28,
    //         day: "2017-11-14",
    //     },
    //     {
    //         value: 370,
    //         day: "2016-12-26",
    //     },
    //     {
    //         value: 49,
    //         day: "2016-09-30",
    //     },
    //     {
    //         value: 107,
    //         day: "2017-11-21",
    //     },
    //     {
    //         value: 211,
    //         day: "2015-08-26",
    //     },
    //     {
    //         value: 237,
    //         day: "2016-08-08",
    //     },
    //     {
    //         value: 185,
    //         day: "2015-12-08",
    //     },
    //     {
    //         value: 316,
    //         day: "2017-05-24",
    //     },
    //     {
    //         value: 41,
    //         day: "2016-02-11",
    //     },
    //     {
    //         value: 298,
    //         day: "2016-04-12",
    //     },
    //     {
    //         value: 218,
    //         day: "2017-10-20",
    //     },
    //     {
    //         value: 315,
    //         day: "2016-12-02",
    //     },
    //     {
    //         value: 84,
    //         day: "2015-07-16",
    //     },
    //     {
    //         value: 215,
    //         day: "2015-07-22",
    //     },
    //     {
    //         value: 44,
    //         day: "2016-02-06",
    //     },
    //     {
    //         value: 361,
    //         day: "2016-06-18",
    //     },
    //     {
    //         value: 91,
    //         day: "2017-01-30",
    //     },
    //     {
    //         value: 135,
    //         day: "2018-04-13",
    //     },
    //     {
    //         value: 127,
    //         day: "2015-11-22",
    //     },
    //     {
    //         value: 204,
    //         day: "2015-04-28",
    //     },
    //     {
    //         value: 165,
    //         day: "2015-08-18",
    //     },
    //     {
    //         value: 215,
    //         day: "2018-07-16",
    //     },
    //     {
    //         value: 383,
    //         day: "2017-03-09",
    //     },
    //     {
    //         value: 230,
    //         day: "2018-03-06",
    //     },
    //     {
    //         value: 2,
    //         day: "2018-05-01",
    //     },
    //     {
    //         value: 115,
    //         day: "2018-03-15",
    //     },
    //     {
    //         value: 82,
    //         day: "2017-12-30",
    //     },
    //     {
    //         value: 383,
    //         day: "2017-03-16",
    //     },
    //     {
    //         value: 299,
    //         day: "2018-06-09",
    //     },
    //     {
    //         value: 301,
    //         day: "2016-11-19",
    //     },
    //     {
    //         value: 30,
    //         day: "2017-11-13",
    //     },
    //     {
    //         value: 135,
    //         day: "2016-04-22",
    //     },
    //     {
    //         value: 345,
    //         day: "2016-07-15",
    //     },
    //     {
    //         value: 320,
    //         day: "2017-09-02",
    //     },
    //     {
    //         value: 231,
    //         day: "2016-11-25",
    //     },
    //     {
    //         value: 347,
    //         day: "2016-12-16",
    //     },
    //     {
    //         value: 27,
    //         day: "2017-11-07",
    //     },
    //     {
    //         value: 104,
    //         day: "2017-05-01",
    //     },
    //     {
    //         value: 188,
    //         day: "2017-08-03",
    //     },
    //     {
    //         value: 118,
    //         day: "2018-04-30",
    //     },
    //     {
    //         value: 393,
    //         day: "2017-03-12",
    //     },
    //     {
    //         value: 3,
    //         day: "2017-01-14",
    //     },
    //     {
    //         value: 81,
    //         day: "2015-12-29",
    //     },
    //     {
    //         value: 396,
    //         day: "2017-02-19",
    //     },
    //     {
    //         value: 138,
    //         day: "2016-06-11",
    //     },
    //     {
    //         value: 201,
    //         day: "2016-02-02",
    //     },
    //     {
    //         value: 296,
    //         day: "2016-02-26",
    //     },
    //     {
    //         value: 51,
    //         day: "2017-11-18",
    //     },
    //     {
    //         value: 333,
    //         day: "2015-05-16",
    //     },
    //     {
    //         value: 250,
    //         day: "2017-09-09",
    //     },
    //     {
    //         value: 125,
    //         day: "2016-06-29",
    //     },
    //     {
    //         value: 298,
    //         day: "2017-03-10",
    //     },
    //     {
    //         value: 62,
    //         day: "2016-09-05",
    //     },
    //     {
    //         value: 196,
    //         day: "2015-11-04",
    //     },
    //     {
    //         value: 91,
    //         day: "2017-02-14",
    //     },
    //     {
    //         value: 218,
    //         day: "2017-07-29",
    //     },
    //     {
    //         value: 39,
    //         day: "2016-04-06",
    //     },
    //     {
    //         value: 40,
    //         day: "2015-06-20",
    //     },
    //     {
    //         value: 303,
    //         day: "2017-09-18",
    //     },
    //     {
    //         value: 272,
    //         day: "2016-08-10",
    //     },
    //     {
    //         value: 193,
    //         day: "2018-06-18",
    //     },
    //     {
    //         value: 270,
    //         day: "2017-07-11",
    //     },
    //     {
    //         value: 286,
    //         day: "2018-01-11",
    //     },
    //     {
    //         value: 113,
    //         day: "2017-11-11",
    //     },
    //     {
    //         value: 131,
    //         day: "2018-01-25",
    //     },
    //     {
    //         value: 207,
    //         day: "2015-05-29",
    //     },
    //     {
    //         value: 24,
    //         day: "2018-03-16",
    //     },
    //     {
    //         value: 10,
    //         day: "2018-03-08",
    //     },
    //     {
    //         value: 159,
    //         day: "2016-12-18",
    //     },
    //     {
    //         value: 25,
    //         day: "2015-10-20",
    //     },
    //     {
    //         value: 337,
    //         day: "2018-04-24",
    //     },
    //     {
    //         value: 224,
    //         day: "2017-01-25",
    //     },
    //     {
    //         value: 23,
    //         day: "2018-05-24",
    //     },
    //     {
    //         value: 280,
    //         day: "2018-07-06",
    //     },
    //     {
    //         value: 315,
    //         day: "2018-05-16",
    //     },
    //     {
    //         value: 185,
    //         day: "2015-07-17",
    //     },
    //     {
    //         value: 360,
    //         day: "2016-02-14",
    //     },
    //     {
    //         value: 91,
    //         day: "2016-12-24",
    //     },
    //     {
    //         value: 59,
    //         day: "2018-02-26",
    //     },
    //     {
    //         value: 16,
    //         day: "2016-05-29",
    //     },
    //     {
    //         value: 300,
    //         day: "2017-01-01",
    //     },
    //     {
    //         value: 272,
    //         day: "2018-04-22",
    //     },
    //     {
    //         value: 379,
    //         day: "2017-12-25",
    //     },
    //     {
    //         value: 55,
    //         day: "2018-06-05",
    //     },
    //     {
    //         value: 315,
    //         day: "2015-09-12",
    //     },
    //     {
    //         value: 307,
    //         day: "2018-08-10",
    //     },
    //     {
    //         value: 340,
    //         day: "2016-08-31",
    //     },
    //     {
    //         value: 315,
    //         day: "2016-01-11",
    //     },
    //     {
    //         value: 269,
    //         day: "2018-05-25",
    //     },
    //     {
    //         value: 365,
    //         day: "2015-10-06",
    //     },
    //     {
    //         value: 353,
    //         day: "2018-06-11",
    //     },
    //     {
    //         value: 232,
    //         day: "2017-10-08",
    //     },
    //     {
    //         value: 360,
    //         day: "2015-10-25",
    //     },
    //     {
    //         value: 200,
    //         day: "2016-04-19",
    //     },
    //     {
    //         value: 359,
    //         day: "2016-04-29",
    //     },
    //     {
    //         value: 14,
    //         day: "2016-04-28",
    //     },
    //     {
    //         value: 264,
    //         day: "2016-07-21",
    //     },
    //     {
    //         value: 47,
    //         day: "2017-07-09",
    //     },
    //     {
    //         value: 397,
    //         day: "2017-09-25",
    //     },
    //     {
    //         value: 292,
    //         day: "2017-09-22",
    //     },
    //     {
    //         value: 111,
    //         day: "2016-08-19",
    //     },
    //     {
    //         value: 190,
    //         day: "2015-05-12",
    //     },
    //     {
    //         value: 215,
    //         day: "2015-11-05",
    //     },
    //     {
    //         value: 11,
    //         day: "2016-08-21",
    //     },
    //     {
    //         value: 286,
    //         day: "2015-12-10",
    //     },
    //     {
    //         value: 73,
    //         day: "2015-07-13",
    //     },
    //     {
    //         value: 89,
    //         day: "2017-05-15",
    //     },
    //     {
    //         value: 191,
    //         day: "2016-12-08",
    //     },
    //     {
    //         value: 228,
    //         day: "2018-05-11",
    //     },
    //     {
    //         value: 108,
    //         day: "2015-08-10",
    //     },
    //     {
    //         value: 149,
    //         day: "2017-03-30",
    //     },
    //     {
    //         value: 58,
    //         day: "2016-05-23",
    //     },
    //     {
    //         value: 296,
    //         day: "2018-03-09",
    //     },
    //     {
    //         value: 290,
    //         day: "2017-02-01",
    //     },
    //     {
    //         value: 120,
    //         day: "2015-08-09",
    //     },
    //     {
    //         value: 210,
    //         day: "2016-04-23",
    //     },
    //     {
    //         value: 226,
    //         day: "2016-10-08",
    //     },
    //     {
    //         value: 312,
    //         day: "2016-08-20",
    //     },
    //     {
    //         value: 210,
    //         day: "2016-02-16",
    //     },
    //     {
    //         value: 206,
    //         day: "2016-07-22",
    //     },
    //     {
    //         value: 182,
    //         day: "2015-11-12",
    //     },
    //     {
    //         value: 90,
    //         day: "2018-05-06",
    //     },
    //     {
    //         value: 344,
    //         day: "2017-06-19",
    //     },
    //     {
    //         value: 80,
    //         day: "2015-09-20",
    //     },
    //     {
    //         value: 38,
    //         day: "2015-07-19",
    //     },
    //     {
    //         value: 328,
    //         day: "2015-06-29",
    //     },
    //     {
    //         value: 72,
    //         day: "2017-07-27",
    //     },
    //     {
    //         value: 315,
    //         day: "2017-11-08",
    //     },
    //     {
    //         value: 309,
    //         day: "2017-12-23",
    //     },
    //     {
    //         value: 298,
    //         day: "2017-01-31",
    //     },
    //     {
    //         value: 262,
    //         day: "2017-05-23",
    //     },
    //     {
    //         value: 182,
    //         day: "2016-02-01",
    //     },
    //     {
    //         value: 211,
    //         day: "2015-06-28",
    //     },
    //     {
    //         value: 289,
    //         day: "2017-06-28",
    //     },
    //     {
    //         value: 247,
    //         day: "2016-06-20",
    //     },
    //     {
    //         value: 166,
    //         day: "2018-02-12",
    //     },
    //     {
    //         value: 170,
    //         day: "2016-06-22",
    //     },
    //     {
    //         value: 27,
    //         day: "2017-12-11",
    //     },
    //     {
    //         value: 61,
    //         day: "2017-07-03",
    //     },
    //     {
    //         value: 11,
    //         day: "2015-05-02",
    //     },
    //     {
    //         value: 187,
    //         day: "2017-02-27",
    //     },
    //     {
    //         value: 45,
    //         day: "2015-10-23",
    //     },
    //     {
    //         value: 184,
    //         day: "2016-03-16",
    //     },
    //     {
    //         value: 255,
    //         day: "2018-06-10",
    //     },
    //     {
    //         value: 124,
    //         day: "2015-04-27",
    //     },
    //     {
    //         value: 192,
    //         day: "2017-04-18",
    //     },
    //     {
    //         value: 114,
    //         day: "2016-12-23",
    //     },
    //     {
    //         value: 34,
    //         day: "2018-04-26",
    //     },
    //     {
    //         value: 181,
    //         day: "2016-07-16",
    //     },
    //     {
    //         value: 342,
    //         day: "2016-01-17",
    //     },
    //     {
    //         value: 332,
    //         day: "2018-02-16",
    //     },
    //     {
    //         value: 251,
    //         day: "2018-04-28",
    //     },
    //     {
    //         value: 255,
    //         day: "2016-11-06",
    //     },
    //     {
    //         value: 42,
    //         day: "2017-02-24",
    //     },
    //     {
    //         value: 298,
    //         day: "2015-11-27",
    //     },
    //     {
    //         value: 381,
    //         day: "2015-08-08",
    //     },
    //     {
    //         value: 214,
    //         day: "2016-09-25",
    //     },
    //     {
    //         value: 135,
    //         day: "2017-10-18",
    //     },
    //     {
    //         value: 163,
    //         day: "2016-05-07",
    //     },
    //     {
    //         value: 361,
    //         day: "2015-04-22",
    //     },
    //     {
    //         value: 293,
    //         day: "2015-04-13",
    //     },
    //     {
    //         value: 391,
    //         day: "2015-08-16",
    //     },
    //     {
    //         value: 389,
    //         day: "2015-05-25",
    //     },
    //     {
    //         value: 324,
    //         day: "2017-06-06",
    //     },
    //     {
    //         value: 237,
    //         day: "2017-09-15",
    //     },
    //     {
    //         value: 2,
    //         day: "2018-06-06",
    //     },
    //     {
    //         value: 226,
    //         day: "2017-10-10",
    //     },
    //     {
    //         value: 71,
    //         day: "2016-02-23",
    //     },
    //     {
    //         value: 240,
    //         day: "2017-07-28",
    //     },
    //     {
    //         value: 227,
    //         day: "2017-08-25",
    //     },
    //     {
    //         value: 277,
    //         day: "2015-12-12",
    //     },
    //     {
    //         value: 147,
    //         day: "2017-09-14",
    //     },
    //     {
    //         value: 114,
    //         day: "2017-05-08",
    //     },
    //     {
    //         value: 308,
    //         day: "2017-08-02",
    //     },
    //     {
    //         value: 123,
    //         day: "2016-02-17",
    //     },
    //     {
    //         value: 296,
    //         day: "2017-08-15",
    //     },
    //     {
    //         value: 346,
    //         day: "2015-11-25",
    //     },
    //     {
    //         value: 229,
    //         day: "2016-12-27",
    //     },
    //     {
    //         value: 330,
    //         day: "2016-05-06",
    //     },
    //     {
    //         value: 248,
    //         day: "2015-12-05",
    //     },
    //     {
    //         value: 175,
    //         day: "2015-09-18",
    //     },
    //     {
    //         value: 311,
    //         day: "2018-01-18",
    //     },
    //     {
    //         value: 161,
    //         day: "2015-06-16",
    //     },
    //     {
    //         value: 99,
    //         day: "2017-12-31",
    //     },
    //     {
    //         value: 161,
    //         day: "2015-08-13",
    //     },
    //     {
    //         value: 206,
    //         day: "2015-07-21",
    //     },
    //     {
    //         value: 279,
    //         day: "2016-07-01",
    //     },
    //     {
    //         value: 62,
    //         day: "2018-07-01",
    //     },
    //     {
    //         value: 322,
    //         day: "2015-05-22",
    //     },
    //     {
    //         value: 221,
    //         day: "2015-06-05",
    //     },
    //     {
    //         value: 149,
    //         day: "2015-12-01",
    //     },
    //     {
    //         value: 133,
    //         day: "2015-11-11",
    //     },
    //     {
    //         value: 335,
    //         day: "2015-11-19",
    //     },
    //     {
    //         value: 273,
    //         day: "2015-12-25",
    //     },
    //     {
    //         value: 338,
    //         day: "2018-01-14",
    //     },
    //     {
    //         value: 165,
    //         day: "2017-06-11",
    //     },
    //     {
    //         value: 230,
    //         day: "2016-10-20",
    //     },
    //     {
    //         value: 165,
    //         day: "2017-08-28",
    //     },
    //     {
    //         value: 78,
    //         day: "2017-11-05",
    //     },
    //     {
    //         value: 149,
    //         day: "2017-01-27",
    //     },
    //     {
    //         value: 213,
    //         day: "2017-10-14",
    //     },
    //     {
    //         value: 267,
    //         day: "2015-07-05",
    //     },
    //     {
    //         value: 346,
    //         day: "2016-12-25",
    //     },
    //     {
    //         value: 271,
    //         day: "2018-06-08",
    //     },
    //     {
    //         value: 299,
    //         day: "2017-10-26",
    //     },
    //     {
    //         value: 250,
    //         day: "2016-03-08",
    //     },
    //     {
    //         value: 322,
    //         day: "2018-07-19",
    //     },
    //     {
    //         value: 141,
    //         day: "2016-07-19",
    //     },
    //     {
    //         value: 333,
    //         day: "2017-10-29",
    //     },
    //     {
    //         value: 145,
    //         day: "2016-11-01",
    //     },
    //     {
    //         value: 330,
    //         day: "2018-01-21",
    //     },
    //     {
    //         value: 398,
    //         day: "2015-09-24",
    //     },
    //     {
    //         value: 115,
    //         day: "2017-02-10",
    //     },
    //     {
    //         value: 19,
    //         day: "2015-04-26",
    //     },
    //     {
    //         value: 236,
    //         day: "2015-04-21",
    //     },
    //     {
    //         value: 378,
    //         day: "2016-05-10",
    //     },
    //     {
    //         value: 239,
    //         day: "2018-04-18",
    //     },
    //     {
    //         value: 9,
    //         day: "2016-11-21",
    //     },
    //     {
    //         value: 113,
    //         day: "2017-09-17",
    //     },
    //     {
    //         value: 113,
    //         day: "2016-11-30",
    //     },
    //     {
    //         value: 4,
    //         day: "2015-08-30",
    //     },
    //     {
    //         value: 216,
    //         day: "2018-01-19",
    //     },
    //     {
    //         value: 207,
    //         day: "2018-02-09",
    //     },
    //     {
    //         value: 178,
    //         day: "2017-07-21",
    //     },
    //     {
    //         value: 133,
    //         day: "2016-05-16",
    //     },
    //     {
    //         value: 237,
    //         day: "2015-05-08",
    //     },
    //     {
    //         value: 16,
    //         day: "2016-01-06",
    //     },
    //     {
    //         value: 179,
    //         day: "2015-05-27",
    //     },
    //     {
    //         value: 197,
    //         day: "2017-05-12",
    //     },
    //     {
    //         value: 400,
    //         day: "2017-01-09",
    //     },
    //     {
    //         value: 268,
    //         day: "2016-05-26",
    //     },
    //     {
    //         value: 156,
    //         day: "2016-01-07",
    //     },
    //     {
    //         value: 38,
    //         day: "2016-11-20",
    //     },
    //     {
    //         value: 59,
    //         day: "2017-02-20",
    //     },
    //     {
    //         value: 333,
    //         day: "2016-09-19",
    //     },
    //     {
    //         value: 225,
    //         day: "2018-01-04",
    //     },
    //     {
    //         value: 244,
    //         day: "2017-09-01",
    //     },
    //     {
    //         value: 258,
    //         day: "2017-02-05",
    //     },
    //     {
    //         value: 394,
    //         day: "2018-04-02",
    //     },
    //     {
    //         value: 194,
    //         day: "2016-10-10",
    //     },
    //     {
    //         value: 68,
    //         day: "2018-06-04",
    //     },
    //     {
    //         value: 284,
    //         day: "2018-08-04",
    //     },
    //     {
    //         value: 350,
    //         day: "2016-02-05",
    //     },
    //     {
    //         value: 275,
    //         day: "2015-05-28",
    //     },
    //     {
    //         value: 167,
    //         day: "2015-11-15",
    //     },
    //     {
    //         value: 175,
    //         day: "2017-07-17",
    //     },
    //     {
    //         value: 223,
    //         day: "2016-12-20",
    //     },
    //     {
    //         value: 317,
    //         day: "2016-11-10",
    //     },
    //     {
    //         value: 85,
    //         day: "2017-12-16",
    //     },
    //     {
    //         value: 34,
    //         day: "2018-05-10",
    //     },
    //     {
    //         value: 260,
    //         day: "2015-06-10",
    //     },
    //     {
    //         value: 25,
    //         day: "2018-04-09",
    //     },
    //     {
    //         value: 269,
    //         day: "2016-02-24",
    //     },
    //     {
    //         value: 82,
    //         day: "2016-12-13",
    //     },
    //     {
    //         value: 224,
    //         day: "2018-01-31",
    //     },
    //     {
    //         value: 368,
    //         day: "2018-06-24",
    //     },
    //     {
    //         value: 373,
    //         day: "2018-08-06",
    //     },
    //     {
    //         value: 30,
    //         day: "2015-08-03",
    //     },
    //     {
    //         value: 219,
    //         day: "2018-04-23",
    //     },
    //     {
    //         value: 220,
    //         day: "2017-11-26",
    //     },
    //     {
    //         value: 65,
    //         day: "2016-01-29",
    //     },
    //     {
    //         value: 56,
    //         day: "2015-10-28",
    //     },
    //     {
    //         value: 183,
    //         day: "2016-01-24",
    //     },
    //     {
    //         value: 357,
    //         day: "2015-06-23",
    //     },
    //     {
    //         value: 320,
    //         day: "2016-03-17",
    //     },
    //     {
    //         value: 109,
    //         day: "2017-06-02",
    //     },
    //     {
    //         value: 369,
    //         day: "2018-02-01",
    //     },
    //     {
    //         value: 179,
    //         day: "2017-07-01",
    //     },
    //     {
    //         value: 374,
    //         day: "2017-03-04",
    //     },
    //     {
    //         value: 232,
    //         day: "2017-06-30",
    //     },
    //     {
    //         value: 296,
    //         day: "2017-10-25",
    //     },
    //     {
    //         value: 184,
    //         day: "2016-06-14",
    //     },
    //     {
    //         value: 369,
    //         day: "2015-12-19",
    //     },
    //     {
    //         value: 27,
    //         day: "2017-03-28",
    //     },
    //     {
    //         value: 361,
    //         day: "2018-08-09",
    //     },
    //     {
    //         value: 250,
    //         day: "2017-02-04",
    //     },
    //     {
    //         value: 208,
    //         day: "2015-11-18",
    //     },
    //     {
    //         value: 345,
    //         day: "2015-09-03",
    //     },
    //     {
    //         value: 280,
    //         day: "2017-04-29",
    //     },
    //     {
    //         value: 145,
    //         day: "2017-12-07",
    //     },
    //     {
    //         value: 316,
    //         day: "2015-10-12",
    //     },
    //     {
    //         value: 106,
    //         day: "2018-07-03",
    //     },
    //     {
    //         value: 377,
    //         day: "2016-04-04",
    //     },
    //     {
    //         value: 143,
    //         day: "2015-05-11",
    //     },
    //     {
    //         value: 221,
    //         day: "2016-05-27",
    //     },
    //     {
    //         value: 8,
    //         day: "2016-08-13",
    //     },
    //     {
    //         value: 291,
    //         day: "2017-05-26",
    //     },
    //     {
    //         value: 60,
    //         day: "2018-05-23",
    //     },
    //     {
    //         value: 190,
    //         day: "2015-12-28",
    //     },
    //     {
    //         value: 192,
    //         day: "2016-04-21",
    //     },
    //     {
    //         value: 248,
    //         day: "2018-07-07",
    //     },
    //     {
    //         value: 125,
    //         day: "2017-09-24",
    //     },
    //     {
    //         value: 191,
    //         day: "2016-10-29",
    //     },
    //     {
    //         value: 249,
    //         day: "2017-09-10",
    //     },
    //     {
    //         value: 326,
    //         day: "2017-04-08",
    //     },
    //     {
    //         value: 331,
    //         day: "2016-06-17",
    //     },
    //     {
    //         value: 332,
    //         day: "2016-04-13",
    //     },
    //     {
    //         value: 99,
    //         day: "2016-03-20",
    //     },
    //     {
    //         value: 1,
    //         day: "2015-09-09",
    //     },
    //     {
    //         value: 372,
    //         day: "2018-03-20",
    //     },
    //     {
    //         value: 50,
    //         day: "2015-04-02",
    //     },
    //     {
    //         value: 111,
    //         day: "2016-03-30",
    //     },
    //     {
    //         value: 45,
    //         day: "2018-02-21",
    //     },
    //     {
    //         value: 111,
    //         day: "2016-01-04",
    //     },
    //     {
    //         value: 305,
    //         day: "2017-07-02",
    //     },
    //     {
    //         value: 363,
    //         day: "2016-10-30",
    //     },
    //     {
    //         value: 337,
    //         day: "2016-01-19",
    //     },
    //     {
    //         value: 355,
    //         day: "2018-03-13",
    //     },
    //     {
    //         value: 7,
    //         day: "2017-03-29",
    //     },
    //     {
    //         value: 48,
    //         day: "2017-08-01",
    //     },
    //     {
    //         value: 262,
    //         day: "2016-06-27",
    //     },
    //     {
    //         value: 65,
    //         day: "2017-03-17",
    //     },
    //     {
    //         value: 282,
    //         day: "2016-07-14",
    //     },
    //     {
    //         value: 262,
    //         day: "2016-09-14",
    //     },
    //     {
    //         value: 76,
    //         day: "2016-11-05",
    //     },
    //     {
    //         value: 5,
    //         day: "2016-10-27",
    //     },
    //     {
    //         value: 35,
    //         day: "2016-06-21",
    //     },
    //     {
    //         value: 201,
    //         day: "2017-11-15",
    //     },
    //     {
    //         value: 276,
    //         day: "2017-10-19",
    //     },
    //     {
    //         value: 148,
    //         day: "2018-02-22",
    //     },
    //     {
    //         value: 175,
    //         day: "2016-05-21",
    //     },
    //     {
    //         value: 213,
    //         day: "2018-07-23",
    //     },
    //     {
    //         value: 278,
    //         day: "2016-06-16",
    //     },
    //     {
    //         value: 50,
    //         day: "2017-08-12",
    //     },
    //     {
    //         value: 281,
    //         day: "2015-08-31",
    //     },
    //     {
    //         value: 383,
    //         day: "2018-01-10",
    //     },
    //     {
    //         value: 36,
    //         day: "2016-08-22",
    //     },
    //     {
    //         value: 42,
    //         day: "2016-03-14",
    //     },
    //     {
    //         value: 198,
    //         day: "2016-09-11",
    //     },
    //     {
    //         value: 21,
    //         day: "2016-04-16",
    //     },
    //     {
    //         value: 190,
    //         day: "2017-07-10",
    //     },
    //     {
    //         value: 226,
    //         day: "2018-01-16",
    //     },
    //     {
    //         value: 196,
    //         day: "2015-08-14",
    //     },
    //     {
    //         value: 5,
    //         day: "2017-11-10",
    //     },
    //     {
    //         value: 100,
    //         day: "2016-02-27",
    //     },
    //     {
    //         value: 162,
    //         day: "2017-06-27",
    //     },
    //     {
    //         value: 136,
    //         day: "2015-07-25",
    //     },
    //     {
    //         value: 337,
    //         day: "2016-10-07",
    //     },
    //     {
    //         value: 180,
    //         day: "2016-09-24",
    //     },
    //     {
    //         value: 291,
    //         day: "2017-04-01",
    //     },
    //     {
    //         value: 362,
    //         day: "2017-11-01",
    //     },
    //     {
    //         value: 350,
    //         day: "2018-08-05",
    //     },
    //     {
    //         value: 243,
    //         day: "2016-09-02",
    //     },
    //     {
    //         value: 320,
    //         day: "2015-09-22",
    //     },
    //     {
    //         value: 72,
    //         day: "2018-03-31",
    //     },
    //     {
    //         value: 94,
    //         day: "2018-06-15",
    //     },
    //     {
    //         value: 222,
    //         day: "2018-04-17",
    //     },
    //     {
    //         value: 250,
    //         day: "2016-08-15",
    //     },
    //     {
    //         value: 367,
    //         day: "2016-12-15",
    //     },
    //     {
    //         value: 71,
    //         day: "2017-02-25",
    //     },
    //     {
    //         value: 283,
    //         day: "2016-10-23",
    //     },
    //     {
    //         value: 189,
    //         day: "2017-11-24",
    //     },
    //     {
    //         value: 357,
    //         day: "2017-06-22",
    //     },
    //     {
    //         value: 127,
    //         day: "2015-10-16",
    //     },
    //     {
    //         value: 234,
    //         day: "2018-01-28",
    //     },
    //     {
    //         value: 336,
    //         day: "2017-08-19",
    //     },
    //     {
    //         value: 175,
    //         day: "2017-03-13",
    //     },
    //     {
    //         value: 241,
    //         day: "2016-05-18",
    //     },
    //     {
    //         value: 115,
    //         day: "2018-05-14",
    //     },
    //     {
    //         value: 255,
    //         day: "2017-03-22",
    //     },
    //     {
    //         value: 371,
    //         day: "2017-04-05",
    //     },
    //     {
    //         value: 152,
    //         day: "2016-04-26",
    //     },
    //     {
    //         value: 47,
    //         day: "2015-05-09",
    //     },
    //     {
    //         value: 27,
    //         day: "2015-09-11",
    //     },
    //     {
    //         value: 66,
    //         day: "2015-05-06",
    //     },
    //     {
    //         value: 37,
    //         day: "2017-11-06",
    //     },
    //     {
    //         value: 164,
    //         day: "2017-08-13",
    //     },
    //     {
    //         value: 91,
    //         day: "2016-06-08",
    //     },
    //     {
    //         value: 170,
    //         day: "2017-04-22",
    //     },
    //     {
    //         value: 253,
    //         day: "2016-08-30",
    //     },
    //     {
    //         value: 395,
    //         day: "2015-10-22",
    //     },
    //     {
    //         value: 275,
    //         day: "2018-02-04",
    //     },
    //     {
    //         value: 60,
    //         day: "2016-05-24",
    //     },
    //     {
    //         value: 167,
    //         day: "2017-03-15",
    //     },
    //     {
    //         value: 84,
    //         day: "2015-11-02",
    //     },
    //     {
    //         value: 132,
    //         day: "2015-11-30",
    //     },
    //     {
    //         value: 306,
    //         day: "2018-03-18",
    //     },
    //     {
    //         value: 99,
    //         day: "2015-07-23",
    //     },
    //     {
    //         value: 144,
    //         day: "2017-08-17",
    //     },
    //     {
    //         value: 221,
    //         day: "2018-04-14",
    //     },
    //     {
    //         value: 175,
    //         day: "2016-09-08",
    //     },
    //     {
    //         value: 100,
    //         day: "2015-06-27",
    //     },
    //     {
    //         value: 230,
    //         day: "2018-03-30",
    //     },
    //     {
    //         value: 372,
    //         day: "2017-01-29",
    //     },
    //     {
    //         value: 326,
    //         day: "2016-10-26",
    //     },
    //     {
    //         value: 140,
    //         day: "2015-07-11",
    //     },
    //     {
    //         value: 118,
    //         day: "2015-07-18",
    //     },
    //     {
    //         value: 228,
    //         day: "2017-03-25",
    //     },
    //     {
    //         value: 78,
    //         day: "2015-12-03",
    //     },
    //     {
    //         value: 385,
    //         day: "2015-09-27",
    //     },
    //     {
    //         value: 14,
    //         day: "2017-09-05",
    //     },
    //     {
    //         value: 319,
    //         day: "2017-06-29",
    //     },
    //     {
    //         value: 303,
    //         day: "2015-07-07",
    //     },
    //     {
    //         value: 170,
    //         day: "2017-05-18",
    //     },
    //     {
    //         value: 152,
    //         day: "2016-10-06",
    //     },
    //     {
    //         value: 20,
    //         day: "2016-01-30",
    //     },
    //     {
    //         value: 327,
    //         day: "2018-07-08",
    //     },
    //     {
    //         value: 6,
    //         day: "2015-05-26",
    //     },
    //     {
    //         value: 220,
    //         day: "2017-09-20",
    //     },
    //     {
    //         value: 354,
    //         day: "2018-05-13",
    //     },
    //     {
    //         value: 387,
    //         day: "2016-07-20",
    //     },
    //     {
    //         value: 44,
    //         day: "2017-08-06",
    //     },
    //     {
    //         value: 151,
    //         day: "2015-09-17",
    //     },
    //     {
    //         value: 323,
    //         day: "2016-12-01",
    //     },
    //     {
    //         value: 370,
    //         day: "2016-05-30",
    //     },
    //     {
    //         value: 275,
    //         day: "2015-09-05",
    //     },
    //     {
    //         value: 61,
    //         day: "2018-02-02",
    //     },
    //     {
    //         value: 341,
    //         day: "2018-03-03",
    //     },
    //     {
    //         value: 213,
    //         day: "2016-06-26",
    //     },
    //     {
    //         value: 61,
    //         day: "2015-04-16",
    //     },
    //     {
    //         value: 64,
    //         day: "2016-09-09",
    //     },
    //     {
    //         value: 389,
    //         day: "2016-12-28",
    //     },
    //     {
    //         value: 35,
    //         day: "2017-04-21",
    //     },
    //     {
    //         value: 308,
    //         day: "2018-01-02",
    //     },
    //     {
    //         value: 150,
    //         day: "2016-10-18",
    //     },
    //     {
    //         value: 215,
    //         day: "2017-12-09",
    //     },
    //     {
    //         value: 326,
    //         day: "2018-07-20",
    //     },
    //     {
    //         value: 243,
    //         day: "2017-06-25",
    //     },
    //     {
    //         value: 246,
    //         day: "2016-09-04",
    //     },
    //     {
    //         value: 398,
    //         day: "2018-05-15",
    //     },
    //     {
    //         value: 338,
    //         day: "2016-10-22",
    //     },
    //     {
    //         value: 133,
    //         day: "2015-10-18",
    //     },
    //     {
    //         value: 196,
    //         day: "2017-03-21",
    //     },
    //     {
    //         value: 324,
    //         day: "2018-05-29",
    //     },
    //     {
    //         value: 355,
    //         day: "2018-03-04",
    //     },
    //     {
    //         value: 178,
    //         day: "2016-07-03",
    //     },
    //     {
    //         value: 391,
    //         day: "2016-11-03",
    //     },
    //     {
    //         value: 150,
    //         day: "2018-02-05",
    //     },
    //     {
    //         value: 270,
    //         day: "2017-09-08",
    //     },
    //     {
    //         value: 219,
    //         day: "2015-10-11",
    //     },
    //     {
    //         value: 239,
    //         day: "2015-10-13",
    //     },
    //     {
    //         value: 36,
    //         day: "2016-08-02",
    //     },
    //     {
    //         value: 275,
    //         day: "2017-10-22",
    //     },
    //     {
    //         value: 188,
    //         day: "2015-06-25",
    //     },
    //     {
    //         value: 24,
    //         day: "2016-08-28",
    //     },
    //     {
    //         value: 291,
    //         day: "2016-11-14",
    //     },
    //     {
    //         value: 180,
    //         day: "2015-06-22",
    //     },
    //     {
    //         value: 113,
    //         day: "2018-06-16",
    //     },
    //     {
    //         value: 174,
    //         day: "2016-07-13",
    //     },
    //     {
    //         value: 79,
    //         day: "2016-06-02",
    //     },
    //     {
    //         value: 349,
    //         day: "2018-01-22",
    //     },
    //     {
    //         value: 236,
    //         day: "2015-09-07",
    //     },
    //     {
    //         value: 35,
    //         day: "2017-12-01",
    //     },
    //     {
    //         value: 384,
    //         day: "2015-06-17",
    //     },
    //     {
    //         value: 257,
    //         day: "2017-09-03",
    //     },
    //     {
    //         value: 273,
    //         day: "2017-12-21",
    //     },
    //     {
    //         value: 332,
    //         day: "2016-01-23",
    //     },
    //     {
    //         value: 331,
    //         day: "2018-04-03",
    //     },
    //     {
    //         value: 385,
    //         day: "2018-06-29",
    //     },
    //     {
    //         value: 299,
    //         day: "2017-06-16",
    //     },
    //     {
    //         value: 306,
    //         day: "2015-10-03",
    //     },
    //     {
    //         value: 133,
    //         day: "2017-08-29",
    //     },
    //     {
    //         value: 75,
    //         day: "2015-12-16",
    //     },
    //     {
    //         value: 360,
    //         day: "2017-04-26",
    //     },
    //     {
    //         value: 260,
    //         day: "2016-12-03",
    //     },
    //     {
    //         value: 204,
    //         day: "2018-07-02",
    //     },
    //     {
    //         value: 342,
    //         day: "2015-06-08",
    //     },
    //     {
    //         value: 164,
    //         day: "2015-10-30",
    //     },
    //     {
    //         value: 300,
    //         day: "2017-01-02",
    //     },
    //     {
    //         value: 6,
    //         day: "2017-03-19",
    //     },
    //     {
    //         value: 62,
    //         day: "2016-03-01",
    //     },
    //     {
    //         value: 92,
    //         day: "2015-11-08",
    //     },
    //     {
    //         value: 153,
    //         day: "2015-06-30",
    //     },
    //     {
    //         value: 376,
    //         day: "2016-03-28",
    //     },
    //     {
    //         value: 165,
    //         day: "2017-09-16",
    //     },
    //     {
    //         value: 182,
    //         day: "2018-01-05",
    //     },
    //     {
    //         value: 108,
    //         day: "2017-10-05",
    //     },
    //     {
    //         value: 75,
    //         day: "2018-02-03",
    //     },
    //     {
    //         value: 150,
    //         day: "2015-08-25",
    //     },
    //     {
    //         value: 59,
    //         day: "2017-11-27",
    //     },
    //     {
    //         value: 1,
    //         day: "2017-02-21",
    //     },
    //     {
    //         value: 202,
    //         day: "2016-03-06",
    //     },
    //     {
    //         value: 109,
    //         day: "2015-08-24",
    //     },
    //     {
    //         value: 204,
    //         day: "2015-12-17",
    //     },
    //     {
    //         value: 75,
    //         day: "2018-04-12",
    //     },
    //     {
    //         value: 176,
    //         day: "2017-07-25",
    //     },
    //     {
    //         value: 259,
    //         day: "2017-04-14",
    //     },
    //     {
    //         value: 34,
    //         day: "2017-06-01",
    //     },
    //     {
    //         value: 322,
    //         day: "2017-02-23",
    //     },
    //     {
    //         value: 265,
    //         day: "2015-07-24",
    //     },
    //     {
    //         value: 140,
    //         day: "2017-10-02",
    //     },
    //     {
    //         value: 269,
    //         day: "2015-06-04",
    //     },
    //     {
    //         value: 16,
    //         day: "2015-07-28",
    //     },
    //     {
    //         value: 130,
    //         day: "2017-02-12",
    //     },
    //     {
    //         value: 348,
    //         day: "2015-08-05",
    //     },
    //     {
    //         value: 79,
    //         day: "2017-01-12",
    //     },
    //     {
    //         value: 34,
    //         day: "2017-07-08",
    //     },
    //     {
    //         value: 97,
    //         day: "2016-11-27",
    //     },
    //     {
    //         value: 113,
    //         day: "2016-01-16",
    //     },
    //     {
    //         value: 393,
    //         day: "2016-01-21",
    //     },
    //     {
    //         value: 374,
    //         day: "2018-03-19",
    //     },
    //     {
    //         value: 382,
    //         day: "2015-12-27",
    //     },
    //     {
    //         value: 103,
    //         day: "2018-03-07",
    //     },
    //     {
    //         value: 337,
    //         day: "2015-05-15",
    //     },
    //     {
    //         value: 78,
    //         day: "2017-04-06",
    //     },
    //     {
    //         value: 228,
    //         day: "2016-03-27",
    //     },
    //     {
    //         value: 231,
    //         day: "2017-06-13",
    //     },
    //     {
    //         value: 55,
    //         day: "2015-10-24",
    //     },
    //     {
    //         value: 264,
    //         day: "2015-11-17",
    //     },
    //     {
    //         value: 169,
    //         day: "2016-11-07",
    //     },
    //     {
    //         value: 123,
    //         day: "2015-12-24",
    //     },
    //     {
    //         value: 330,
    //         day: "2015-09-01",
    //     },
    //     {
    //         value: 225,
    //         day: "2017-12-04",
    //     },
    //     {
    //         value: 21,
    //         day: "2018-04-08",
    //     },
    //     {
    //         value: 392,
    //         day: "2017-01-06",
    //     },
    //     {
    //         value: 183,
    //         day: "2018-05-12",
    //     },
    //     {
    //         value: 110,
    //         day: "2015-10-29",
    //     },
    //     {
    //         value: 123,
    //         day: "2015-10-09",
    //     },
    //     {
    //         value: 257,
    //         day: "2018-06-07",
    //     },
    //     {
    //         value: 308,
    //         day: "2017-06-05",
    //     },
    //     {
    //         value: 365,
    //         day: "2016-06-28",
    //     },
    //     {
    //         value: 53,
    //         day: "2017-11-17",
    //     },
    //     {
    //         value: 52,
    //         day: "2016-05-15",
    //     },
    //     {
    //         value: 250,
    //         day: "2017-10-01",
    //     },
    //     {
    //         value: 224,
    //         day: "2016-04-09",
    //     },
    //     {
    //         value: 309,
    //         day: "2017-02-15",
    //     },
    //     {
    //         value: 321,
    //         day: "2017-05-04",
    //     },
    //     {
    //         value: 236,
    //         day: "2017-01-26",
    //     },
    //     {
    //         value: 208,
    //         day: "2018-02-18",
    //     },
    //     {
    //         value: 375,
    //         day: "2015-06-01",
    //     },
    //     {
    //         value: 160,
    //         day: "2017-01-24",
    //     },
    //     {
    //         value: 168,
    //         day: "2018-01-12",
    //     },
    //     {
    //         value: 239,
    //         day: "2017-07-22",
    //     },
    //     {
    //         value: 8,
    //         day: "2015-08-27",
    //     },
    //     {
    //         value: 192,
    //         day: "2016-02-20",
    //     },
    //     {
    //         value: 201,
    //         day: "2015-04-03",
    //     },
    //     {
    //         value: 18,
    //         day: "2015-04-30",
    //     },
    //     {
    //         value: 63,
    //         day: "2016-03-21",
    //     },
    //     {
    //         value: 345,
    //         day: "2016-03-12",
    //     },
    //     {
    //         value: 355,
    //         day: "2016-07-25",
    //     },
    //     {
    //         value: 251,
    //         day: "2017-07-19",
    //     },
    //     {
    //         value: 133,
    //         day: "2018-03-22",
    //     },
    //     {
    //         value: 18,
    //         day: "2017-08-20",
    //     },
    //     {
    //         value: 74,
    //         day: "2016-10-31",
    //     },
    //     {
    //         value: 250,
    //         day: "2016-04-02",
    //     },
    //     {
    //         value: 271,
    //         day: "2015-11-09",
    //     },
    //     {
    //         value: 181,
    //         day: "2016-08-11",
    //     },
    //     {
    //         value: 390,
    //         day: "2015-08-28",
    //     },
    //     {
    //         value: 81,
    //         day: "2016-12-05",
    //     },
    //     {
    //         value: 47,
    //         day: "2016-01-31",
    //     },
    //     {
    //         value: 327,
    //         day: "2015-09-26",
    //     },
    //     {
    //         value: 322,
    //         day: "2015-11-23",
    //     },
    //     {
    //         value: 155,
    //         day: "2016-09-23",
    //     },
    //     {
    //         value: 223,
    //         day: "2015-07-03",
    //     },
    //     {
    //         value: 386,
    //         day: "2018-02-14",
    //     },
    //     {
    //         value: 242,
    //         day: "2016-01-08",
    //     },
    //     {
    //         value: 249,
    //         day: "2016-08-16",
    //     },
    //     {
    //         value: 97,
    //         day: "2018-06-21",
    //     },
    //     {
    //         value: 147,
    //         day: "2018-07-25",
    //     },
    //     {
    //         value: 316,
    //         day: "2016-07-27",
    //     },
    //     {
    //         value: 338,
    //         day: "2016-12-09",
    //     },
    //     {
    //         value: 49,
    //         day: "2017-11-22",
    //     },
    //     {
    //         value: 300,
    //         day: "2017-04-09",
    //     },
    //     {
    //         value: 177,
    //         day: "2017-12-10",
    //     },
    //     {
    //         value: 388,
    //         day: "2015-04-08",
    //     },
    //     {
    //         value: 375,
    //         day: "2016-09-15",
    //     },
    //     {
    //         value: 315,
    //         day: "2015-11-21",
    //     },
    //     {
    //         value: 189,
    //         day: "2016-12-17",
    //     },
    //     {
    //         value: 103,
    //         day: "2015-11-06",
    //     },
    //     {
    //         value: 255,
    //         day: "2015-09-13",
    //     },
    //     {
    //         value: 68,
    //         day: "2015-12-11",
    //     },
    //     {
    //         value: 242,
    //         day: "2018-01-30",
    //     },
    //     {
    //         value: 108,
    //         day: "2016-01-18",
    //     },
    //     {
    //         value: 177,
    //         day: "2016-01-15",
    //     },
    //     {
    //         value: 294,
    //         day: "2017-09-07",
    //     },
    //     {
    //         value: 311,
    //         day: "2017-07-30",
    //     },
    //     {
    //         value: 264,
    //         day: "2016-04-25",
    //     },
    //     {
    //         value: 350,
    //         day: "2017-10-03",
    //     },
    //     {
    //         value: 53,
    //         day: "2017-10-27",
    //     },
    //     {
    //         value: 302,
    //         day: "2015-06-24",
    //     },
    //     {
    //         value: 4,
    //         day: "2018-01-13",
    //     },
    //     {
    //         value: 148,
    //         day: "2018-04-01",
    //     },
    //     {
    //         value: 126,
    //         day: "2015-07-08",
    //     },
    //     {
    //         value: 310,
    //         day: "2018-05-08",
    //     },
    //     {
    //         value: 64,
    //         day: "2015-05-17",
    //     },
    //     {
    //         value: 14,
    //         day: "2016-05-03",
    //     },
    //     {
    //         value: 272,
    //         day: "2015-07-04",
    //     },
    //     {
    //         value: 130,
    //         day: "2016-08-06",
    //     },
    //     {
    //         value: 311,
    //         day: "2015-05-24",
    //     },
    //     {
    //         value: 92,
    //         day: "2016-06-23",
    //     },
    //     {
    //         value: 347,
    //         day: "2017-05-02",
    //     },
    //     {
    //         value: 256,
    //         day: "2016-01-12",
    //     },
    //     {
    //         value: 73,
    //         day: "2017-04-04",
    //     },
    //     {
    //         value: 4,
    //         day: "2015-11-14",
    //     },
    //     {
    //         value: 376,
    //         day: "2016-10-13",
    //     },
    //     {
    //         value: 34,
    //         day: "2016-07-12",
    //     },
    //     {
    //         value: 134,
    //         day: "2017-04-19",
    //     },
    //     {
    //         value: 71,
    //         day: "2018-04-07",
    //     },
    //     {
    //         value: 109,
    //         day: "2015-06-03",
    //     },
    //     {
    //         value: 313,
    //         day: "2015-10-01",
    //     },
    //     {
    //         value: 18,
    //         day: "2017-10-06",
    //     },
    //     {
    //         value: 390,
    //         day: "2018-04-05",
    //     },
    //     {
    //         value: 53,
    //         day: "2016-07-26",
    //     },
    //     {
    //         value: 262,
    //         day: "2016-09-28",
    //     },
    //     {
    //         value: 225,
    //         day: "2018-01-03",
    //     },
    //     {
    //         value: 263,
    //         day: "2015-10-10",
    //     },
    //     {
    //         value: 278,
    //         day: "2018-07-11",
    //     },
    //     {
    //         value: 226,
    //         day: "2017-01-17",
    //     },
    //     {
    //         value: 184,
    //         day: "2018-03-12",
    //     },
    //     {
    //         value: 123,
    //         day: "2017-06-20",
    //     },
    //     {
    //         value: 100,
    //         day: "2016-12-22",
    //     },
    //     {
    //         value: 229,
    //         day: "2015-08-11",
    //     },
    //     {
    //         value: 292,
    //         day: "2015-10-31",
    //     },
    //     {
    //         value: 185,
    //         day: "2018-04-04",
    //     },
    //     {
    //         value: 154,
    //         day: "2016-12-29",
    //     },
    //     {
    //         value: 345,
    //         day: "2016-01-05",
    //     },
    //     {
    //         value: 35,
    //         day: "2015-05-10",
    //     },
    //     {
    //         value: 114,
    //         day: "2015-07-26",
    //     },
    //     {
    //         value: 200,
    //         day: "2015-04-07",
    //     },
    //     {
    //         value: 351,
    //         day: "2016-10-28",
    //     },
    //     {
    //         value: 116,
    //         day: "2016-09-01",
    //     },
    //     {
    //         value: 170,
    //         day: "2015-11-07",
    //     },
    //     {
    //         value: 141,
    //         day: "2015-12-26",
    //     },
    //     {
    //         value: 117,
    //         day: "2018-01-17",
    //     },
    //     {
    //         value: 289,
    //         day: "2016-09-26",
    //     },
    //     {
    //         value: 6,
    //         day: "2016-12-10",
    //     },
    //     {
    //         value: 236,
    //         day: "2015-07-02",
    //     },
    //     {
    //         value: 88,
    //         day: "2015-06-15",
    //     },
    //     {
    //         value: 219,
    //         day: "2018-02-23",
    //     },
    //     {
    //         value: 241,
    //         day: "2016-07-02",
    //     },
    //     {
    //         value: 214,
    //         day: "2016-04-15",
    //     },
    //     {
    //         value: 215,
    //         day: "2015-08-23",
    //     },
    //     {
    //         value: 271,
    //         day: "2016-03-15",
    //     },
    //     {
    //         value: 346,
    //         day: "2015-04-15",
    //     },
    //     {
    //         value: 153,
    //         day: "2018-03-23",
    //     },
    //     {
    //         value: 109,
    //         day: "2015-10-05",
    //     },
    //     {
    //         value: 187,
    //         day: "2017-10-28",
    //     },
    //     {
    //         value: 211,
    //         day: "2018-02-28",
    //     },
    //     {
    //         value: 111,
    //         day: "2017-11-28",
    //     },
    //     {
    //         value: 159,
    //         day: "2018-05-20",
    //     },
    //     {
    //         value: 250,
    //         day: "2016-01-28",
    //     },
    //     {
    //         value: 221,
    //         day: "2016-01-01",
    //     },
    //     {
    //         value: 276,
    //         day: "2017-05-30",
    //     },
    //     {
    //         value: 164,
    //         day: "2018-01-23",
    //     },
    //     {
    //         value: 193,
    //         day: "2018-05-22",
    //     },
    //     {
    //         value: 137,
    //         day: "2017-08-24",
    //     },
    //     {
    //         value: 15,
    //         day: "2015-10-08",
    //     },
    //     {
    //         value: 281,
    //         day: "2015-05-03",
    //     },
    //     {
    //         value: 352,
    //         day: "2015-07-09",
    //     },
    //     {
    //         value: 353,
    //         day: "2017-03-02",
    //     },
    //     {
    //         value: 26,
    //         day: "2016-12-06",
    //     },
    //     {
    //         value: 74,
    //         day: "2015-04-19",
    //     },
    //     {
    //         value: 231,
    //         day: "2017-02-22",
    //     },
    //     {
    //         value: 150,
    //         day: "2015-06-06",
    //     },
    //     {
    //         value: 267,
    //         day: "2017-10-12",
    //     },
    //     {
    //         value: 161,
    //         day: "2016-05-14",
    //     },
    //     {
    //         value: 367,
    //         day: "2015-06-18",
    //     },
    //     {
    //         value: 302,
    //         day: "2017-08-27",
    //     },
    //     {
    //         value: 326,
    //         day: "2016-08-05",
    //     },
    //     {
    //         value: 252,
    //         day: "2017-03-18",
    //     },
    //     {
    //         value: 16,
    //         day: "2015-05-07",
    //     },
    //     {
    //         value: 389,
    //         day: "2017-07-24",
    //     },
    //     {
    //         value: 254,
    //         day: "2016-11-08",
    //     },
    //     {
    //         value: 173,
    //         day: "2017-02-03",
    //     },
    //     {
    //         value: 366,
    //         day: "2015-05-14",
    //     },
    //     {
    //         value: 25,
    //         day: "2016-07-30",
    //     },
    //     {
    //         value: 128,
    //         day: "2015-10-14",
    //     },
    //     {
    //         value: 270,
    //         day: "2016-08-14",
    //     },
    //     {
    //         value: 81,
    //         day: "2017-08-09",
    //     },
    //     {
    //         value: 263,
    //         day: "2015-09-06",
    //     },
    //     {
    //         value: 166,
    //         day: "2015-12-14",
    //     },
    //     {
    //         value: 395,
    //         day: "2017-12-05",
    //     },
    //     {
    //         value: 105,
    //         day: "2016-10-05",
    //     },
    //     {
    //         value: 195,
    //         day: "2018-02-11",
    //     },
    //     {
    //         value: 226,
    //         day: "2016-09-03",
    //     },
    //     {
    //         value: 289,
    //         day: "2016-06-15",
    //     },
    //     {
    //         value: 397,
    //         day: "2018-03-26",
    //     },
    //     {
    //         value: 347,
    //         day: "2018-06-19",
    //     },
    //     {
    //         value: 171,
    //         day: "2016-02-04",
    //     },
    //     {
    //         value: 81,
    //         day: "2015-12-02",
    //     },
    //     {
    //         value: 363,
    //         day: "2018-07-30",
    //     },
    //     {
    //         value: 2,
    //         day: "2017-03-11",
    //     },
    //     {
    //         value: 71,
    //         day: "2016-11-09",
    //     },
    //     {
    //         value: 126,
    //         day: "2017-08-30",
    //     },
    //     {
    //         value: 158,
    //         day: "2016-08-17",
    //     },
    //     {
    //         value: 80,
    //         day: "2018-03-02",
    //     },
    //     {
    //         value: 204,
    //         day: "2017-09-23",
    //     },
    //     {
    //         value: 278,
    //         day: "2017-03-08",
    //     },
    //     {
    //         value: 111,
    //         day: "2015-12-09",
    //     },
    //     {
    //         value: 389,
    //         day: "2015-12-15",
    //     },
    //     {
    //         value: 48,
    //         day: "2016-08-04",
    //     },
    //     {
    //         value: 36,
    //         day: "2015-05-01",
    //     },
    //     {
    //         value: 212,
    //         day: "2016-11-04",
    //     },
    //     {
    //         value: 145,
    //         day: "2015-08-07",
    //     },
    //     {
    //         value: 243,
    //         day: "2017-01-15",
    //     },
    //     {
    //         value: 197,
    //         day: "2015-11-26",
    //     },
    //     {
    //         value: 383,
    //         day: "2017-05-16",
    //     },
    //     {
    //         value: 60,
    //         day: "2017-12-18",
    //     },
    //     {
    //         value: 19,
    //         day: "2017-08-21",
    //     },
    //     {
    //         value: 352,
    //         day: "2017-12-29",
    //     },
    //     {
    //         value: 8,
    //         day: "2017-09-30",
    //     },
    //     {
    //         value: 365,
    //         day: "2015-10-04",
    //     },
    //     {
    //         value: 297,
    //         day: "2016-08-09",
    //     },
    //     {
    //         value: 347,
    //         day: "2017-08-22",
    //     },
    //     {
    //         value: 280,
    //         day: "2018-06-13",
    //     },
    //     {
    //         value: 288,
    //         day: "2017-10-11",
    //     },
    //     {
    //         value: 52,
    //         day: "2017-08-10",
    //     },
    //     {
    //         value: 16,
    //         day: "2016-06-10",
    //     },
    //     {
    //         value: 85,
    //         day: "2018-03-01",
    //     },
    //     {
    //         value: 194,
    //         day: "2016-02-08",
    //     },
    //     {
    //         value: 161,
    //         day: "2015-07-14",
    //     },
    //     {
    //         value: 112,
    //         day: "2017-09-19",
    //     },
    //     {
    //         value: 177,
    //         day: "2017-01-08",
    //     },
    //     {
    //         value: 131,
    //         day: "2016-02-13",
    //     },
    //     {
    //         value: 161,
    //         day: "2017-12-02",
    //     },
    //     {
    //         value: 292,
    //         day: "2018-07-14",
    //     },
    //     {
    //         value: 44,
    //         day: "2016-04-10",
    //     },
    //     {
    //         value: 1,
    //         day: "2017-05-09",
    //     },
    //     {
    //         value: 349,
    //         day: "2017-08-18",
    //     },
    //     {
    //         value: 195,
    //         day: "2018-01-07",
    //     },
    //     {
    //         value: 132,
    //         day: "2017-07-20",
    //     },
    //     {
    //         value: 36,
    //         day: "2015-05-05",
    //     },
    //     {
    //         value: 101,
    //         day: "2016-03-26",
    //     },
    //     {
    //         value: 46,
    //         day: "2015-09-16",
    //     },
    //     {
    //         value: 216,
    //         day: "2017-01-16",
    //     },
    //     {
    //         value: 317,
    //         day: "2018-05-07",
    //     },
    //     {
    //         value: 170,
    //         day: "2017-03-03",
    //     },
    //     {
    //         value: 12,
    //         day: "2016-09-07",
    //     },
    //     {
    //         value: 225,
    //         day: "2018-01-24",
    //     },
    //     {
    //         value: 172,
    //         day: "2016-06-24",
    //     },
    //     {
    //         value: 19,
    //         day: "2018-02-19",
    //     },
    // ];
    // const [filterDate, setValues] = useState("");

    //REGION GRÁFICO PARA RESUMOS OBRAS EMPRESARIAIS FILTRADO PELA DATA

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMsg(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const [companyWorkResumesGraph, setCompanyWorkResumesGraph] = useState([]);
    const [totalHoursCompanyWorks, setTotalHoursCompanyWorks] = useState(0);
    const [totalCostCompanyWorks, setTotalCostCompanyWorks] = useState(0);
    const [totalProfitCompanyWorks, setTotalProfitCompanyWorks] = useState(0);

    const [
        generalLoadingCompanyWorkResumes,
        setGeneralLoadingCompanyWorkResumes,
    ] = useState(false);
    const [
        generalLoadingPrivateWorkResumes,
        setGeneralLoadingPrivateWorkResumes,
    ] = useState(false);

    const [monthCompanyWorkResumes, setMonthCompanyWorkResumes] = useState(
        subMonths(new Date(), 1)
    );

    const { data: companyWorkResumes, isLoadingCompanyWorkResumes } =
        useGetAllCompanyWorkResumesByMonthResumeQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllCompanyWorkResumesByMonthResume, companyWorkResumesLazy] =
        useLazyGetAllCompanyWorkResumesByMonthResumeQuery();

    const [hoursSumCompanyAux, setHoursSumCompanyAux] = useState(0);
    const [costSumCompanyAux, setCostSumCompanyAux] = useState(0);
    const [profitSumCompanyAux, setProfitSumCompanyAux] = useState(0);
    const [
        companyWorkResumesGraphAuxGraph,
        setCompanyWorkResumesGraphAuxGraph,
    ] = useState([]);

    useEffect(() => {
        if (
            hoursSumCompanyAux !== 0 &&
            costSumCompanyAux !== 0 &&
            profitSumCompanyAux !== 0 &&
            companyWorkResumesGraphAuxGraph &&
            companyWorkResumesGraphAuxGraph.length > 0
        ) {
            setCompanyWorkResumesGraph(companyWorkResumesGraphAuxGraph);
            setTotalHoursCompanyWorks(hoursSumCompanyAux);
            setTotalCostCompanyWorks(costSumCompanyAux);
            setTotalProfitCompanyWorks(profitSumCompanyAux);
            setGeneralLoadingCompanyWorkResumes(false);
        }
    }, [
        hoursSumCompanyAux,
        costSumCompanyAux,
        profitSumCompanyAux,
        companyWorkResumesGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let companyWorkResumesGraphAux = [];

        if (companyWorkResumes && companyWorkResumes.length > 0) {
            setGeneralLoadingCompanyWorkResumes(true);
            hoursSum = companyWorkResumes.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = companyWorkResumes.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = companyWorkResumes.reduce(
                (total, item) => total + item.profit,
                0
            );
            companyWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setCompanyWorkResumesGraphAuxGraph(companyWorkResumesGraphAux);
            setHoursSumCompanyAux(hoursSum.toFixed(2));
            setCostSumCompanyAux(costSum.toFixed(2));
            setProfitSumCompanyAux(profitSum.toFixed(2));
        }

        setCompanyWorkResumesGraph(companyWorkResumesGraphAux);
        setTotalHoursCompanyWorks(hoursSum);
        setTotalCostCompanyWorks(costSum);
        setTotalProfitCompanyWorks(profitSum);
    }, [companyWorkResumes]);

    const handleGetAllCompanyWorkResumesByMonthResumeSubmit = async () => {
        try {
            if (monthCompanyWorkResumes) {
                setGeneralLoadingCompanyWorkResumes(true);
                let filterDate = {
                    month: new Date(monthCompanyWorkResumes).getMonth() + 1,
                    year: new Date(monthCompanyWorkResumes).getFullYear(),
                };
                await getAllCompanyWorkResumesByMonthResume(filterDate);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            setGeneralLoadingCompanyWorkResumes(false);
            handleSnackbarOpen(err, "error");
        }
    };

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let companyWorkResumesGraphAux = [];
        if (
            companyWorkResumesLazy.data &&
            companyWorkResumesLazy.data.length > 0
        ) {
            hoursSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = companyWorkResumesLazy.data.reduce(
                (total, item) => total + item.profit,
                0
            );
            companyWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: companyWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setCompanyWorkResumesGraphAuxGraph(companyWorkResumesGraphAux);
            setHoursSumCompanyAux(hoursSum.toFixed(2));
            setCostSumCompanyAux(costSum.toFixed(2));
            setProfitSumCompanyAux(profitSum.toFixed(2));
        }

        setTotalHoursCompanyWorks(hoursSum);
        setTotalCostCompanyWorks(costSum);
        setTotalProfitCompanyWorks(profitSum);
        setCompanyWorkResumesGraph(companyWorkResumesGraphAux);
        setGeneralLoadingCompanyWorkResumes(false);
    }, [companyWorkResumesLazy]);

    //ENDREGION GRÁFICO PARA RESUMOS OBRAS EMPRESARIAIS FILTRADO PELA DATA

    //REGION GRÁFICO PARA RESUMOS OBRAS FILTRADO PELO CLIENT ID E DATA
    const [workResumesByClientIdGraph, setWorkResumesByClientIdGraph] =
        useState([]);

    const [
        totalHoursWorkResumesByClientId,
        setTotalHoursWorkResumesByClientId,
    ] = useState(0);

    const [totalCostWorkResumesByClientId, setTotalCostWorkResumesByClientId] =
        useState(0);

    const [
        totalProfitWorkResumesByClientId,
        setTotalProfitWorkResumesByClientId,
    ] = useState(0);

    const [
        generalLoadingWorkResumesByClientId,
        setGeneralLoadingWorkResumesByClientId,
    ] = useState(false);

    const [monthWorkResumesByClientIdDate, setMonthWorkResumesByClientIdDate] =
        useState(subMonths(new Date(), 1));

    const { data: clients, isLoadingClients } = useGetClientsQuery();

    const [getAllWorkResumesByClientId, workResumesByClientIdLazy] =
        useLazyGetAllWorkResumesByClientIdAndMonthResumeQuery();

    const [clientId, setClientId] = useState("");

    useEffect(() => {
        if (clients && clients.length > 0) {
            try {
                setGeneralLoadingWorkResumesByClientId(true);
                setClientId(clients[0].id);

                let filterData = {
                    clientId: clients[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };

                getAllWorkResumesByClientId(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingWorkResumesByClientId(false);
            }
        }
    }, [clients]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let workResumesByClientIdGraphAux = [];
        if (
            workResumesByClientIdLazy.data &&
            workResumesByClientIdLazy.data.length > 0
        ) {
            hoursSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            profitSum = workResumesByClientIdLazy.data.reduce(
                (total, item) => total + item.profit,
                0
            );
            workResumesByClientIdGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: workResumesByClientIdLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.profit,
                    })),
                },
            ];

            setTotalHoursWorkResumesByClientId(hoursSum.toFixed(2));
            setTotalCostWorkResumesByClientId(costSum.toFixed(2));
            setTotalProfitWorkResumesByClientId(profitSum.toFixed(2));
            setWorkResumesByClientIdGraph(workResumesByClientIdGraphAux);
        } else {
            setTotalHoursWorkResumesByClientId(0);
            setTotalCostWorkResumesByClientId(0);
            setTotalProfitWorkResumesByClientId(0);
            setWorkResumesByClientIdGraph([]);
        }
        setGeneralLoadingWorkResumesByClientId(false);
    }, [workResumesByClientIdLazy]);

    const handleGetWorkResumesByClientIdAndDateSubmit = async () => {
        try {
            if (clientId && monthWorkResumesByClientIdDate) {
                setGeneralLoadingWorkResumesByClientId(true);

                let filterData = {
                    clientId: clientId,
                    month:
                        new Date(monthWorkResumesByClientIdDate).getMonth() + 1,
                    year: new Date(
                        monthWorkResumesByClientIdDate
                    ).getFullYear(),
                };

                getAllWorkResumesByClientId(filterData);
            } else {
                handleSnackbarOpen(t("requiredClientId"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingWorkResumesByClientId(false);
        }
    };
    //ENDREGION GRÁFICO PARA RESUMOS OBRAS FILTRADO PELO CLIENT ID E DATA

    //REGION GRÁFICO PARA RESUMOS OBRAS PARTICULARES FILTRADO PELA DATA
    const [privateWorkResumesGraph, setPrivateWorkResumesGraph] = useState([]);
    const [totalHoursPrivateWorks, setTotalHoursPrivateWorks] = useState(0);
    const [totalCostPrivateWorks, setTotalCostPrivateWorks] = useState(0);
    const [totalProfitPrivateWorks, setTotalProfitPrivateWorks] = useState(0);

    const [monthPrivateWorkResumes, setMonthPrivateWorkResumes] = useState(
        subMonths(new Date(), 1)
    );

    const { data: privateWorkResumes, isLoadingPrivateWorkResumes } =
        useGetAllPrivateWorkResumesByMonthResumeQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllPrivateWorkResumesByMonthResume, privateWorkResumesLazy] =
        useLazyGetAllPrivateWorkResumesByMonthResumeQuery();

    const [hoursSumPrivateAux, setHoursSumPrivateAux] = useState(0);
    const [costSumPrivateAux, setCostSumPrivateAux] = useState(0);
    // const [profitSumPrivateAux, setProfitSumPrivateAux] = useState(0);
    const [
        privateWorkResumesGraphAuxGraph,
        setPrivateWorkResumesGraphAuxGraph,
    ] = useState([]);

    useEffect(() => {
        if (
            hoursSumPrivateAux !== 0 &&
            costSumPrivateAux !== 0 &&
            privateWorkResumesGraphAuxGraph &&
            privateWorkResumesGraphAuxGraph.length > 0
        ) {
            setPrivateWorkResumesGraph(privateWorkResumesGraphAuxGraph);
            setTotalHoursPrivateWorks(hoursSumPrivateAux);
            setTotalCostPrivateWorks(costSumPrivateAux);
            // setTotalProfitPrivateWorks(profitSumPrivateAux);
            setGeneralLoadingPrivateWorkResumes(false);
        }
    }, [
        hoursSumPrivateAux,
        costSumPrivateAux,
        privateWorkResumesGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let privateWorkResumesGraphAux = [];

        if (privateWorkResumes && privateWorkResumes.length > 0) {
            setGeneralLoadingPrivateWorkResumes(true);
            hoursSum = privateWorkResumes.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = privateWorkResumes.reduce(
                (total, item) => total + item.cost,
                0
            );
            privateWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: privateWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: privateWorkResumes.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
            ];
            setHoursSumPrivateAux(hoursSum.toFixed(2));
            setCostSumPrivateAux(costSum.toFixed(2));
            setPrivateWorkResumesGraphAuxGraph(privateWorkResumesGraphAux);
        }

        setPrivateWorkResumesGraph(privateWorkResumesGraphAux);
        setTotalHoursPrivateWorks(hoursSum);
        setTotalCostPrivateWorks(costSum);
    }, [privateWorkResumes]);

    const handleGetAllPrivateWorkResumesByMonthResumeSubmit = async () => {
        try {
            if (monthPrivateWorkResumes) {
                setGeneralLoadingPrivateWorkResumes(true);
                let filterDate = {
                    month: new Date(monthPrivateWorkResumes).getMonth() + 1,
                    year: new Date(monthPrivateWorkResumes).getFullYear(),
                };
                await getAllPrivateWorkResumesByMonthResume(filterDate);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingPrivateWorkResumes(false);
        }
    };

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let privateWorkResumesGraphAux = [];
        if (
            privateWorkResumesLazy.data &&
            privateWorkResumesLazy.data.length > 0
        ) {
            hoursSum = privateWorkResumesLazy.data.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = privateWorkResumesLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );
            privateWorkResumesGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: privateWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: privateWorkResumesLazy.data.map((workResume) => ({
                        x:
                            workResume.workPlanning.name +
                            " - " +
                            workResume.workPlanning.work.name,
                        y: workResume.cost,
                    })),
                },
            ];
            setHoursSumPrivateAux(hoursSum.toFixed(2));
            setCostSumPrivateAux(costSum.toFixed(2));
            setPrivateWorkResumesGraphAuxGraph(privateWorkResumesGraphAux);
        }

        setTotalHoursPrivateWorks(hoursSum);
        setTotalCostPrivateWorks(costSum);
        setPrivateWorkResumesGraph(privateWorkResumesGraphAux);
        setGeneralLoadingPrivateWorkResumes(false);
    }, [privateWorkResumesLazy]);

    //ENDREGION GRÁFICO PARA RESUMOS OBRAS PARTICULARES FILTRADO PELA DATA

    //REGION EMPLOYEE COSTS BY WORKPLANNING ID AND DATE
    const { data: companyWorkPlannings, isLoadingCompanyWorkPlannings } =
        useGetAllCompanyWorkPlanningsQuery();

    const { data: privateWorkPlannings, isLoadingPrivateWorkPlannings } =
        useGetAllPrivateWorkPlanningsQuery();

    const [
        filterDateToEmployeeCostCompanyWorkPlanning,
        setFilterDateToEmployeeCostCompanyWorkPlanning,
    ] = useState(subMonths(new Date(), 1));

    const [
        filterDateToEmployeeCostPrivateWorkPlanning,
        setFilterDateToEmployeeCostPrivateWorkPlanning,
    ] = useState(subMonths(new Date(), 1));

    const [companyWorkPlanningId, setCompanyWorkPlanningId] = useState("");
    const [privateWorkPlanningId, setPrivateWorkPlanningId] = useState("");

    const [
        numberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning,
        setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);
    const [
        costsSumEmpoyeeCostsByCompanyWorkPlanning,
        setCostsSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);
    const [
        profitSumEmpoyeeCostsByCompanyWorkPlanning,
        setProfitSumEmpoyeeCostsByCompanyWorkPlanning,
    ] = useState(0);

    const [
        numberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning,
        setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning,
    ] = useState(0);
    const [
        costsSumEmpoyeeCostsByPrivateWorkPlanning,
        setCostsSumEmpoyeeCostsByPrivateWorkPlanning,
    ] = useState(0);

    const [
        empoyeeCostsByCompanyWorkPlanningIdAndDateGraph,
        setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph,
    ] = useState([]);
    const [
        empoyeeCostsByPrivateWorkPlanningIdAndDateGraph,
        setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph,
    ] = useState([]);

    const [
        generalLoadindEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
        setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
    ] = useState(false);
    const [
        generalLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
        setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
    ] = useState(false);

    const [
        getEmpoyeeCostsByCompanyWorkPlanningIdAndDate,
        empoyeeCostsByCompanyWorkPlanningIdAndDate,
    ] = useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery();

    const [
        getEmpoyeeCostsByPrivateWorkPlanningIdAndDate,
        empoyeeCostsByPrivateWorkPlanningIdAndDate,
    ] = useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery();

    useEffect(() => {
        if (companyWorkPlannings && companyWorkPlannings.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    true
                );
                setCompanyWorkPlanningId(companyWorkPlannings[0].id);
                let filterData = {
                    workPlanningId: companyWorkPlannings[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };
                getEmpoyeeCostsByCompanyWorkPlanningIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    false
                );
            }
        }
    }, [companyWorkPlannings]);

    useEffect(() => {
        if (privateWorkPlannings && privateWorkPlannings.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    true
                );
                setPrivateWorkPlanningId(privateWorkPlannings[0].id);
                let filterData = {
                    workPlanningId: privateWorkPlannings[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };

                getEmpoyeeCostsByPrivateWorkPlanningIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    false
                );
            }
        }
    }, [privateWorkPlannings]);

    useEffect(() => {
        if (
            empoyeeCostsByCompanyWorkPlanningIdAndDate.data &&
            empoyeeCostsByCompanyWorkPlanningIdAndDate.data.length > 0
        ) {
            const hoursSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total + item.employeeRegist.workHoursTotalByWork,
                    0
                );
            const costSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total +
                        item.employeeRegist.workHoursTotalByWork *
                            item.clearance.hourCost,
                    0
                );
            const profitSum =
                empoyeeCostsByCompanyWorkPlanningIdAndDate.data.reduce(
                    (total, item) => total + item.clearance.workProfit,
                    0
                );

            const empoyeeCostsByCompanyWorkPlanningIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y:
                                employeeCost.clearance.hourCost *
                                employeeCost.employeeRegist
                                    .workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByCompanyWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workProfit,
                        })
                    ),
                },
            ];

            setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning(
                hoursSum.toFixed(2)
            );
            setCostsSumEmpoyeeCostsByCompanyWorkPlanning(costSum.toFixed(2));
            setProfitSumEmpoyeeCostsByCompanyWorkPlanning(profitSum.toFixed(2));
            setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph(
                empoyeeCostsByCompanyWorkPlanningIdAndDateGraphAux
            );
        } else {
            setNumberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setCostsSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setProfitSumEmpoyeeCostsByCompanyWorkPlanning(0);
            setEmpoyeeCostsByCompanyWorkPlanningIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(false);
    }, [empoyeeCostsByCompanyWorkPlanningIdAndDate]);

    useEffect(() => {
        if (
            empoyeeCostsByPrivateWorkPlanningIdAndDate.data &&
            empoyeeCostsByPrivateWorkPlanningIdAndDate.data.length > 0
        ) {
            const hoursSum =
                empoyeeCostsByPrivateWorkPlanningIdAndDate.data.reduce(
                    (total, item) =>
                        total + item.employeeRegist.workHoursTotalByWork,
                    0
                );
            const costSum =
                empoyeeCostsByPrivateWorkPlanningIdAndDate.data.reduce(
                    (total, item) => total + item.clearance.workCost,
                    0
                );
            const empoyeeCostsByPrivateWorkPlanningIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: empoyeeCostsByPrivateWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: empoyeeCostsByPrivateWorkPlanningIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workCost,
                        })
                    ),
                },
            ];

            setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning(
                hoursSum.toFixed(2)
            );
            setCostsSumEmpoyeeCostsByPrivateWorkPlanning(costSum.toFixed(2));
            setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph(
                empoyeeCostsByPrivateWorkPlanningIdAndDateGraphAux
            );
        } else {
            setNumberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning(0);
            setCostsSumEmpoyeeCostsByPrivateWorkPlanning(0);
            setEmpoyeeCostsByPrivateWorkPlanningIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(false);
    }, [empoyeeCostsByPrivateWorkPlanningIdAndDate]);

    const handleGetEmpoyeeCostsByCompanyWorkPlanningIdAndDateSubmit =
        async () => {
            try {
                if (
                    companyWorkPlanningId &&
                    filterDateToEmployeeCostCompanyWorkPlanning
                ) {
                    setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                        true
                    );
                    let filterData = {
                        workPlanningId: companyWorkPlanningId,
                        month:
                            new Date(
                                filterDateToEmployeeCostCompanyWorkPlanning
                            ).getMonth() + 1,
                        year: new Date(
                            filterDateToEmployeeCostCompanyWorkPlanning
                        ).getFullYear(),
                    };
                    getEmpoyeeCostsByCompanyWorkPlanningIdAndDate(filterData);
                } else {
                    handleSnackbarOpen(t("requiredWorkPlanningId"), "error");
                }
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByCompanyWorkPlanningIdAndDate(
                    false
                );
            }
        };

    const handleGetEmpoyeeCostsByPrivateWorkPlanningIdAndDateSubmit =
        async () => {
            try {
                if (
                    privateWorkPlanningId &&
                    filterDateToEmployeeCostPrivateWorkPlanning
                ) {
                    setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                        true
                    );
                    let filterData = {
                        workPlanningId: privateWorkPlanningId,
                        month:
                            new Date(
                                filterDateToEmployeeCostPrivateWorkPlanning
                            ).getMonth() + 1,
                        year: new Date(
                            filterDateToEmployeeCostPrivateWorkPlanning
                        ).getFullYear(),
                    };
                    getEmpoyeeCostsByPrivateWorkPlanningIdAndDate(filterData);
                } else {
                    handleSnackbarOpen(t("requiredWorkPlanningId"), "error");
                }
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate(
                    false
                );
            }
        };
    //ENDREGION EMPLOYEE COSTS BY WORKPLANNING ID AND DATE

    //REGION EMPLOYEE COSTS BY SUB CONTRACTOR ID AND DATE
    const { data: subContractors, isLoadingSubContractors } =
        useGetSubContractorsQuery();

    const [subContractorId, setSubContractorId] = useState("");

    const [sumHoursSucontractors, setSumHoursSubcontractors] = useState(0);
    const [sumProfitSubcontractors, setSumProfitSubcontractors] = useState(0);

    const [
        generalLoadindEmpoyeeCostsBySubcontractorIdAndDate,
        setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate,
    ] = useState(false);

    const [
        empoyeeCostsBySubcontractorIdAndDateGraph,
        setEmpoyeeCostsBySubcontractorIdAndDateGraph,
    ] = useState([]);

    const [
        filterDateToEmployeeCostSubContractor,
        setFilterDateToEmployeeCostSubContractor,
    ] = useState(subMonths(new Date(), 1));

    const [
        getEmpoyeeCostsBySucontractorIdAndDate,
        employeeCostsBySubcontractorIdAndDate,
    ] = useLazyGetEmpoyeeCostsBySucontractorIdAndDateQuery();

    useEffect(() => {
        if (subContractors && subContractors.length > 0) {
            try {
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(true);
                setSubContractorId(subContractors[0].id);

                let filterData = {
                    subcontractorId: subContractors[0].id,
                    month: subMonths(new Date(), 1).getMonth() + 1,
                    year: subMonths(new Date(), 1).getFullYear(),
                };
                getEmpoyeeCostsBySucontractorIdAndDate(filterData);
            } catch (err) {
                handleSnackbarOpen(err, "error");
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
            }
        }
    }, [subContractors]);

    useEffect(() => {
        if (
            employeeCostsBySubcontractorIdAndDate.data &&
            employeeCostsBySubcontractorIdAndDate.data.length > 0
        ) {
            const hoursSum = employeeCostsBySubcontractorIdAndDate.data.reduce(
                (total, item) =>
                    total + item.employeeRegist.workHoursTotalByWork,
                0
            );
            const profitSum = employeeCostsBySubcontractorIdAndDate.data.reduce(
                (total, item) => total + item.clearance.workProfit,
                0
            );

            const empoyeeCostsBySubcontractorIdAndDateGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: employeeCostsBySubcontractorIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.employeeRegist.workHoursTotalByWork,
                        })
                    ),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: employeeCostsBySubcontractorIdAndDate.data.map(
                        (employeeCost) => ({
                            x: employeeCost.employee.name,
                            y: employeeCost.clearance.workProfit,
                        })
                    ),
                },
            ];
            setSumHoursSubcontractors(hoursSum.toFixed(2));
            setSumProfitSubcontractors(profitSum.toFixed(2));
            setEmpoyeeCostsBySubcontractorIdAndDateGraph(
                empoyeeCostsBySubcontractorIdAndDateGraphAux
            );
        } else {
            setSumHoursSubcontractors(0);
            setSumProfitSubcontractors(0);
            setEmpoyeeCostsBySubcontractorIdAndDateGraph([]);
        }
        setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
    }, [employeeCostsBySubcontractorIdAndDate]);

    const handleGetEmpoyeeCostsSubcontractorIdAndDateSubmit = async () => {
        try {
            if (subContractorId && filterDateToEmployeeCostSubContractor) {
                setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(true);

                let filterData = {
                    subcontractorId: subContractorId,
                    month:
                        new Date(
                            filterDateToEmployeeCostSubContractor
                        ).getMonth() + 1,
                    year: new Date(
                        filterDateToEmployeeCostSubContractor
                    ).getFullYear(),
                };
                getEmpoyeeCostsBySucontractorIdAndDate(filterData);
            } else {
                handleSnackbarOpen(t("requiredSubcontractorId"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingEmpoyeeCostsBySubcontractorIdAndDate(false);
        }
    };
    //ENDREGION EMPLOYEE COSTS BY SUB CONTRACTOR ID AND DATE

    //REGION COST CATEGORIES RESUME BY DATE
    const [costCategoriesResumeGraph, setCostCategoriesResumeGraph] = useState(
        []
    );

    const [
        generalLoadingCostCategoriesResume,
        setGeneralLoadingCostCategoriesResume,
    ] = useState(false);

    const [monthCostCategoriesResume, setMonthCostCategoriesResume] = useState(
        subMonths(new Date(), 1)
    );

    const { data: costCategoriesResume, isLoadingCostCategoriesResume } =
        useGetCostCategoriesResumeByDateQuery({
            month: subMonths(new Date(), 1).getMonth() + 1,
            year: subMonths(new Date(), 1).getFullYear(),
        });

    const [getAllCostCategoriesResumeByMonthResume, costCategoriesResumeLazy] =
        useLazyGetCostCategoriesResumeByDateQuery();

    const [costCategoriesResumeTotal, setCostCategoriesResumeTotal] =
        useState(0);

    const [costSumCostCategoriesAux, setCostSumCostCategoriesAux] = useState(0);

    const [costCategoriesGraphAuxGraph, setCostCategoriesGraphAuxGraph] =
        useState([]);

    useEffect(() => {
        if (
            costSumCostCategoriesAux !== 0 &&
            costCategoriesGraphAuxGraph &&
            costCategoriesGraphAuxGraph.length > 0
        ) {
            setCostCategoriesResumeTotal(costSumCostCategoriesAux);
            setCostCategoriesResumeGraph(costCategoriesGraphAuxGraph);
            setGeneralLoadingCostCategoriesResume(false);
        }
    }, [costSumCostCategoriesAux, costCategoriesGraphAuxGraph]);

    useEffect(() => {
        let costSum = 0;
        let costCategoriesResumeGraphAux = [];

        if (costCategoriesResume && costCategoriesResume.length > 0) {
            setGeneralLoadingCostCategoriesResume(true);

            costSum = costCategoriesResume.reduce(
                (total, item) => total + item.cost,
                0
            );

            costCategoriesResumeGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: costCategoriesResume.map((costCategoryResume) => ({
                        x: costCategoryResume.costCategoryCategory.name,
                        y: costCategoryResume.cost,
                    })),
                },
            ];
            setCostSumCostCategoriesAux(costSum.toFixed(2));
            setCostCategoriesGraphAuxGraph(costCategoriesResumeGraphAux);
        }

        setCostCategoriesResumeTotal(costSum.toFixed(2));
        setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);
        setGeneralLoadingCostCategoriesResume(false);
    }, [costCategoriesResume]);

    const handleGetCostCategoriesResumeByDateSubmit = async () => {
        try {
            if (monthCostCategoriesResume) {
                setGeneralLoadingCostCategoriesResume(true);

                let filterData = {
                    month: new Date(monthCostCategoriesResume).getMonth() + 1,
                    year: new Date(monthCostCategoriesResume).getFullYear(),
                };
                await getAllCostCategoriesResumeByMonthResume(filterData);
            } else {
                handleSnackbarOpen(t("requiredDate"), "error");
            }
        } catch (err) {
            handleSnackbarOpen(err, "error");
            setGeneralLoadingCostCategoriesResume(false);
        }
    };

    useEffect(() => {
        let costSum = 0;
        let costCategoriesResumeGraphAux = [];

        if (
            costCategoriesResumeLazy.data &&
            costCategoriesResumeLazy.data.length > 0
        ) {
            costSum = costCategoriesResumeLazy.data.reduce(
                (total, item) => total + item.cost,
                0
            );

            costCategoriesResumeGraphAux = [
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: costCategoriesResumeLazy.data.map(
                        (costCategoryResume) => ({
                            x: costCategoryResume.costCategoryCategory.name,
                            y: costCategoryResume.cost,
                        })
                    ),
                },
            ];

            setCostCategoriesResumeTotal(costSum.toFixed(2));
            setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);
        }
        setCostCategoriesResumeTotal(costSum.toFixed(2));
        setCostCategoriesResumeGraph(costCategoriesResumeGraphAux);

        setGeneralLoadingCostCategoriesResume(false);
    }, [costCategoriesResumeLazy]);
    //ENDREGION COST CATEGORIES RESUME BY DATE

    //REIGON MONTH RESUMES BY YEAR
    const [generalLoadingMonthResumes, setGeneralLoadingMonthResumes] =
        useState(false);

    // const [monthResumes, setMonthResumes] = useState(subMonths(new Date(), 1));

    const [totalHoursMonthResumesByYear, setTotalHoursMonthResumesByYear] =
        useState(0);
    const [totalCostsMonthResumesByYear, setTotalCostsMonthResumesByYear] =
        useState(0);
    const [totalProfitMonthResumesByYear, setTotalProfitMonthResumesByYear] =
        useState(0);

    const { data: monthResumesByYear, isLoadingMonthResumesByYear } =
        useGetMonthResumesByYearQuery();

    // const [getMonthResumesByYear, monthResumesByYearLazy] =
    //     useLazyGetMonthResumesByYearQuery();

    const [monthResumesByYearGraph, setMonthResumesByYearGraph] = useState([]);

    const [hoursSumMonthResumeAux, setHoursSumMonthResumeAux] = useState(0);
    const [costSumMonthResumeAux, setCostSumMonthResumeAux] = useState(0);
    const [profitSumMonthResumeAux, setProfitSumMonthResumeAux] = useState(0);
    const [monthResumeGraphAuxGraph, setMonthResumeGraphAuxGraph] = useState(
        []
    );

    useEffect(() => {
        if (
            hoursSumMonthResumeAux !== 0 &&
            costSumMonthResumeAux !== 0 &&
            profitSumMonthResumeAux !== 0 &&
            monthResumeGraphAuxGraph &&
            monthResumeGraphAuxGraph.length > 0
        ) {
            setTotalHoursMonthResumesByYear(hoursSumMonthResumeAux);
            setTotalCostsMonthResumesByYear(costSumMonthResumeAux);
            setTotalProfitMonthResumesByYear(profitSumMonthResumeAux);
            setMonthResumesByYearGraph(monthResumeGraphAuxGraph);
            setGeneralLoadingMonthResumes(false);
        }
    }, [
        hoursSumMonthResumeAux,
        costSumMonthResumeAux,
        profitSumMonthResumeAux,
        monthResumeGraphAuxGraph,
    ]);

    useEffect(() => {
        let hoursSum = 0;
        let costSum = 0;
        let profitSum = 0;
        let monthResumesByYearGraphAux = [];

        if (monthResumesByYear && monthResumesByYear.length > 0) {
            setGeneralLoadingMonthResumes(true);
            hoursSum = monthResumesByYear.reduce(
                (total, item) => total + item.hours,
                0
            );
            costSum = monthResumesByYear.reduce(
                (total, item) => total + item.costs,
                0
            );
            profitSum = monthResumesByYear.reduce(
                (total, item) => total + item.profit,
                0
            );

            monthResumesByYearGraphAux = [
                {
                    id: t("hours"),
                    color: "hsl(265, 70%, 50%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.hours,
                    })),
                },
                {
                    id: t("cost"),
                    color: "hsl(121, 60%, 23%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.costs,
                    })),
                },
                {
                    id: t("profit"),
                    color: "hsl(265, 70%, 50%)",
                    data: monthResumesByYear.map((monthResume) => ({
                        x: new Date(monthResume.monthResumeDate).getFullYear(),
                        y: monthResume.profit,
                    })),
                },
            ];

            setHoursSumMonthResumeAux(hoursSum.toFixed(2));
            setCostSumMonthResumeAux(costSum.toFixed(2));
            setProfitSumMonthResumeAux(profitSum.toFixed(2));
            setMonthResumeGraphAuxGraph(monthResumesByYearGraphAux);
        }

        setTotalHoursMonthResumesByYear(hoursSum);
        setTotalCostsMonthResumesByYear(costSum);
        setTotalProfitMonthResumesByYear(profitSum);
        setMonthResumesByYearGraph(monthResumesByYearGraphAux);
        setGeneralLoadingMonthResumes(false);
    }, [monthResumesByYear]);

    // const handleGetMonthResumesByYearSubmit = async () => {
    //     try {
    //         if (monthResumes) {
    //             setGeneralLoadingMonthResumes(true);
    //             await getMonthResumesByYear();
    //         } else {
    //             handleSnackbarOpen(t("requiredDate"), "error");
    //         }
    //     } catch (err) {
    //         handleSnackbarOpen(err, "error");
    //         setGeneralLoadingMonthResumes(false);
    //     }
    // };

    // useEffect(() => {
    //     let hoursSum = 0;
    //     let costSum = 0;
    //     let profitSum = 0;
    //     let monthResumesByYearGraphAux = [];

    //     if (
    //         monthResumesByYearLazy.data &&
    //         monthResumesByYearLazy.data.length > 0
    //     ) {
    //         hoursSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.hours,
    //             0
    //         );
    //         costSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.costs,
    //             0
    //         );
    //         profitSum = monthResumesByYearLazy.data.reduce(
    //             (total, item) => total + item.profit,
    //             0
    //         );

    //         monthResumesByYearGraphAux = [
    //             {
    //                 id: t("hours"),
    //                 color: "hsl(265, 70%, 50%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.hours,
    //                 })),
    //             },
    //             {
    //                 id: t("cost"),
    //                 color: "hsl(121, 60%, 23%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.costs,
    //                 })),
    //             },
    //             {
    //                 id: t("profit"),
    //                 color: "hsl(265, 70%, 50%)",
    //                 data: monthResumesByYearLazy.data.map((monthResume) => ({
    //                     x: new Date(monthResume.monthResumeDate).getFullYear(),
    //                     y: monthResume.profit,
    //                 })),
    //             },
    //         ];

    //         setTotalHoursMonthResumesByYear(hoursSum.toFixed(2));
    //         setTotalCostsMonthResumesByYear(costSum.toFixed(2));
    //         setTotalProfitMonthResumesByYear(profitSum.toFixed(2));
    //         setMonthResumesByYearGraph(monthResumesByYearGraphAux);
    //     }

    //     setTotalHoursMonthResumesByYear(hoursSum);
    //     setTotalCostsMonthResumesByYear(costSum);
    //     setTotalProfitMonthResumesByYear(profitSum);
    //     setMonthResumesByYearGraph(monthResumesByYearGraphAux);
    //     setGeneralLoadingMonthResumes(false);
    // }, [monthResumesByYearLazy.data]);

    //ENDREGION MONTH RESUMES BY YEAR
    return (
        <Box
            m={!isNonMobile ? "0 " : "1.5rem 2.5rem"}
            display="flex"
            flexDirection="column"
            gap="100px"
        >
            {isLoadingCompanyWorkResumes ||
            isLoadingPrivateWorkResumes ||
            generalLoadindEmpoyeeCostsByCompanyWorkPlanningIdAndDate ||
            generalLoadingEmpoyeeCostsByPrivateWorkPlanningIdAndDate ||
            generalLoadingCompanyWorkResumes ||
            generalLoadindEmpoyeeCostsBySubcontractorIdAndDate ||
            isLoadingClients ||
            generalLoadingWorkResumesByClientId ||
            isLoadingCostCategoriesResume ||
            generalLoadingCostCategoriesResume ||
            generalLoadingMonthResumes ||
            isLoadingMonthResumesByYear ||
            generalLoadingPrivateWorkResumes ? (
                <LoadingSpinner />
            ) : (
                <>
                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("companies")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {companyWorkResumesGraph &&
                                companyWorkResumesGraph.length > 0 ? (
                                    <LineChart data={companyWorkResumesGraph} />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursCompanyWorks}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostCompanyWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitCompanyWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthCompanyWorkResumes)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthCompanyWorkResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetAllCompanyWorkResumesByMonthResumeSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("employees")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsByCompanyWorkPlanningIdAndDateGraph &&
                                empoyeeCostsByCompanyWorkPlanningIdAndDateGraph.length >
                                    0 ? (
                                    <LineChart
                                        data={
                                            empoyeeCostsByCompanyWorkPlanningIdAndDateGraph
                                        }
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {
                                            numberOfHoursSumEmpoyeeCostsByCompanyWorkPlanning
                                        }
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>

                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costsSumEmpoyeeCostsByCompanyWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {profitSumEmpoyeeCostsByCompanyWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingCompanyWorkPlannings}
                                    options={companyWorkPlannings || []}
                                    getOptionLabel={(option) =>
                                        option.name + " - " + option.work.name
                                    }
                                    value={
                                        (companyWorkPlannings &&
                                            companyWorkPlannings.find(
                                                (workPlanning) =>
                                                    workPlanning.id ===
                                                    companyWorkPlanningId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setCompanyWorkPlanningId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("work")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostCompanyWorkPlanning
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostCompanyWorkPlanning(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsByCompanyWorkPlanningIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            color="black"
                            fontSize="32px"
                            fontWeight="700"
                        >
                            {t("private")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {privateWorkResumesGraph &&
                                privateWorkResumesGraph.length > 0 ? (
                                    <LineChart data={privateWorkResumesGraph} />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursPrivateWorks}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostPrivateWorks + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthPrivateWorkResumes)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthPrivateWorkResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetAllPrivateWorkResumesByMonthResumeSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            color="black"
                            fontSize="32px"
                            fontWeight="700"
                        >
                            {t("employees")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsByPrivateWorkPlanningIdAndDateGraph &&
                                empoyeeCostsByPrivateWorkPlanningIdAndDateGraph.length >
                                    0 ? (
                                    <LineChart
                                        data={
                                            empoyeeCostsByPrivateWorkPlanningIdAndDateGraph
                                        }
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        fontSize="32px"
                                        fontWeight="700"
                                        color="black"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {
                                            numberOfHoursSumEmpoyeeCostsByPrivateWorkPlanning
                                        }
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costsSumEmpoyeeCostsByPrivateWorkPlanning +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingPrivateWorkPlannings}
                                    options={privateWorkPlannings || []}
                                    getOptionLabel={(option) =>
                                        option.name + " - " + option.work.name
                                    }
                                    value={
                                        (privateWorkPlannings &&
                                            privateWorkPlannings.find(
                                                (workPlanning) =>
                                                    workPlanning.id ===
                                                    privateWorkPlanningId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setPrivateWorkPlanningId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("work")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostPrivateWorkPlanning
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostPrivateWorkPlanning(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsByPrivateWorkPlanningIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("subContractors")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {empoyeeCostsBySubcontractorIdAndDateGraph &&
                                empoyeeCostsBySubcontractorIdAndDateGraph.length >
                                    0 ? (
                                    <LineChartSubContractor
                                        data={
                                            empoyeeCostsBySubcontractorIdAndDateGraph
                                        }
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {sumHoursSucontractors}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {sumProfitSubcontractors + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingSubContractors}
                                    options={subContractors || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (subContractors &&
                                            subContractors.find(
                                                (subContractor) =>
                                                    subContractor.id ===
                                                    subContractorId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setSubContractorId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("subContractor")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            filterDateToEmployeeCostSubContractor
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setFilterDateToEmployeeCostSubContractor(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetEmpoyeeCostsSubcontractorIdAndDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("clients")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {workResumesByClientIdGraph &&
                                workResumesByClientIdGraph.length > 0 ? (
                                    <LineChart
                                        data={workResumesByClientIdGraph}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursWorkResumesByClientId}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostWorkResumesByClientId + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitWorkResumesByClientId +
                                            " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                <Autocomplete
                                    variant="outlined"
                                    loading={isLoadingClients}
                                    options={clients || []}
                                    getOptionLabel={(option) => option.name}
                                    value={
                                        (clients &&
                                            clients.find(
                                                (client) =>
                                                    client.id === clientId
                                            )) ||
                                        null
                                    }
                                    onChange={(event, newValue) => {
                                        setClientId(
                                            newValue ? newValue.id : ""
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <FormControl fullWidth>
                                            <TextField
                                                label={t("client")}
                                                {...params}
                                            />
                                        </FormControl>
                                    )}
                                />
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(
                                            monthWorkResumesByClientIdDate
                                        )}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthWorkResumesByClientIdDate(
                                                value
                                            )
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetWorkResumesByClientIdAndDateSubmit
                                    }
                                    type="button"
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("costCategories")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="80%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {costCategoriesResumeGraph &&
                                costCategoriesResumeGraph.length > 0 ? (
                                    <LineChartCostCategories
                                        data={costCategoriesResumeGraph}
                                    />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="20%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {costCategoriesResumeTotal + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        textAlign="center"
                                        fontSize="32px"
                                        fontWeight="500"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["month", "year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthCostCategoriesResume)}
                                        format="MM/YYYY"
                                        onChange={(value) =>
                                            setMonthCostCategoriesResume(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={
                                        handleGetCostCategoriesResumeByDateSubmit
                                    }
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>

                    <Box
                        display="grid"
                        gridTemplateColumns={
                            !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                        }
                        boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                        padding="20px"
                        borderRadius="20px"
                        marginBottom="100px"
                    >
                        <Typography
                            variant="h1"
                            component="h2"
                            fontSize="32px"
                            fontWeight="700"
                            color="black"
                        >
                            {t("balance")}
                        </Typography>
                        <Box display="flex" width="100%">
                            <Box
                                maxHeight="600px"
                                width="78%"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                {monthResumesByYearGraph &&
                                monthResumesByYearGraph.length > 0 ? (
                                    <LineChart data={monthResumesByYearGraph} />
                                ) : (
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        fontSize="32px"
                                        fontWeight="700"
                                    >
                                        {t("withoutData")}
                                    </Typography>
                                )}
                            </Box>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                gap="30px"
                                width="22%"
                                padding="30px"
                            >
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalHoursMonthResumesByYear}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalHours")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalCostsMonthResumesByYear + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalCost")}
                                    </Typography>
                                </Box>
                                <Box
                                    boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="center"
                                    sx={{
                                        borderRadius: "20px",
                                    }}
                                >
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        color="black"
                                        textAlign="center"
                                    >
                                        {totalProfitMonthResumesByYear + " €"}
                                    </Typography>
                                    <Typography
                                        variant="h2"
                                        component="h2"
                                        color="#c8c8c8"
                                        fontSize="32px"
                                        fontWeight="500"
                                        textAlign="center"
                                    >
                                        {t("totalProfit")}
                                    </Typography>
                                </Box>
                                {/* <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year"]}
                                        label={t("monthResume")}
                                        value={dayjs(monthResumes)}
                                        format="YYYY"
                                        onChange={(value) =>
                                            setMonthResumes(value)
                                        }
                                    />
                                </LocalizationProvider>
                                <LoadingButton
                                    fullWidth
                                    onClick={handleGetMonthResumesByYearSubmit}
                                    sx={{
                                        width: "100%",
                                        p: "1rem",
                                        backgroundColor: "black",
                                        color: "white",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(0, 0, 0, 0.8)",
                                            color: "white",
                                        },
                                    }}
                                >
                                    {t("filtering")}
                                </LoadingButton> */}
                            </Box>
                        </Box>
                    </Box>

                    <Snackbar
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackBar}
                    >
                        <Alert
                            onClose={handleCloseSnackBar}
                            severity={snackbarSeverity}
                            sx={{
                                mt: "10px",
                                backgroundColor:
                                    snackbarSeverity === "success"
                                        ? "#10781F"
                                        : "#ff000082",
                                alignItems: "center",
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#fff",
                            }}
                        >
                            {snackbarMsg}
                        </Alert>
                    </Snackbar>
                    {/* <Box
                    height="70vh"
                    maxHeight="70vh"
                    display="grid"
                    gap="1rem"
                    gridTemplateColumns={
                        !isNonMobile ? "repeat(1, 1fr)" : "repeat(1, 1fr)"
                    }
                >
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <LineChart data={lineData} />
                    </Box> */}
                    {/* <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <BarChart data={barData} />
                    </Box>
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <PieChart data={pieData} />
                    </Box>
                    <Box height="500px" width={!isNonMobile ? "100vw" : "100%"}>
                        <CalendarChart data={calendarData} />
                    </Box> */}
                    {/* <PowerBIEmbed
                        embedConfig={{
                            type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                            id: "56b3e9a3-ea49-46ee-9452-0bf8c7a09c97",
                            embedUrl:
                                "https://app.powerbi.com/reportEmbed?reportId=56b3e9a3-ea49-46ee-9452-0bf8c7a09c97&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1yZWRpcmVjdC5hbmFseXNpcy53aW5kb3dzLm5ldCIsImVtYmVkRmVhdHVyZXMiOnsidXNhZ2VNZXRyaWNzVk5leHQiOnRydWUsImRpc2FibGVBbmd1bGFySlNCb290c3RyYXBSZXBvcnRFbWJlZCI6dHJ1ZX19",
                            accessToken:
                                "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSIsImtpZCI6IjVCM25SeHRRN2ppOGVORGMzRnkwNUtmOTdaRSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvN2E2MGE2MjktZTYxZi00MzBjLTljNjMtMjY2NjUzYjJiODk5LyIsImlhdCI6MTcwNTQwMjkyMSwibmJmIjoxNzA1NDAyOTIxLCJleHAiOjE3MDU0MDc3MDIsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJFMlZnWUdoUU4zZE5DZndlOGJ2L1NZbUlabnR4dDIzN3p4MXBTZ0dQWlRRc3pWM0M1b2UvOEEvek9KdktIRDZwNHFiYS9nLy9BQT09IiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMCIsImZhbWlseV9uYW1lIjoiTW9yZWlyYSIsImdpdmVuX25hbWUiOiJIdWdvIiwiaXBhZGRyIjoiMjAwMTo4MTg6ZGI5MDo0MzAwOjU1NmQ6MTIxMDpiNTg3OjRmMGMiLCJuYW1lIjoiSHVnbyBNb3JlaXJhICgxMTYxMjY5KSIsIm9pZCI6ImIzZWZjM2ZiLWEzMTgtNDc0ZS05YWIwLTdjZDliNTQxMjM4OSIsInB1aWQiOiIxMDAzN0ZGRTlBOEYxMUJDIiwicmgiOiIwLkFRVUFLYVpnZWhfbURFT2NZeVptVTdLNG1Ra0FBQUFBQUFBQXdBQUFBQUFBQUFBYkFZQS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzdWIiOiJ4NzNJT2Z2V0hhckdmRVFvMThKUzhFZW9Cc0hneGRlYjUyUm1CNE1KQjl3IiwidGlkIjoiN2E2MGE2MjktZTYxZi00MzBjLTljNjMtMjY2NjUzYjJiODk5IiwidW5pcXVlX25hbWUiOiIxMTYxMjY5QGlzZXAuaXBwLnB0IiwidXBuIjoiMTE2MTI2OUBpc2VwLmlwcC5wdCIsInV0aSI6InJUWWJ3UXZMcFUtTFJQTUZqV09FQUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdLCJ4bXNfcGwiOiJwdC1QVCJ9.2xSdOH-nmKp-2osMRzHYDjxd_FEHRmlsx3CmKbn-hv4XXJTexiU7W3Ox2l6XTRkhi5pqUKyPs0uv6rH-DxbWUk39_6iYYgMsYtxi5PbWfYCW_JZmAkhRe_tvXFQYtkOESx9IBEhbzErfB5VfStdhxvyth9JsqGazIm6mdurmfr8ielTMBewbMwNcppg3KfbAfvLCP-PURxNkWVKevc0aWKO0h-Li2hJhvMdnYPDLiSOZhk1OqcXnKPcvc2jpjXownW-x9xeOPbzMHxDozt4-6jtLUSfSexBw09TPlrjFVKak6fFFZa8KOeHc6iYtBLPkWgLY95euPAl39tRbBvBRsg",
                            tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    },
                                },
                                background: models.BackgroundType.Transparent,
                            },
                        }}
                        eventHandlers={
                            new Map([
                                [
                                    "loaded",
                                    function () {
                                        console.log("Report loaded");
                                    },
                                ],
                                [
                                    "rendered",
                                    function () {
                                        console.log("Report rendered");
                                    },
                                ],
                                [
                                    "error",
                                    function (event) {
                                        console.log(event.detail);
                                    },
                                ],
                                [
                                    "visualClicked",
                                    () => console.log("visual clicked"),
                                ],
                                ["pageChanged", (event) => console.log(event)],
                            ])
                        }
                        cssClassName={"Embed-container"}
                        getEmbeddedComponent={(embeddedReport) => {
                            window.Report = embeddedReport;
                        }}
                    /> */}
                    {/* </Box> */}
                </>
            )}
        </Box>
    );
};

export default HomePage;
