import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "../../features/auth/authSlice";

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL || "http://localhost:5051/api",
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        const user = getState().auth.user;
        const token = getState().auth.token;
        if (user) {
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);

    // If you want, handle other status codes, too
    if (result?.error?.status === 403 || result?.error?.status === 401) {
        args.method = "POST";
        // send refresh token to get new access token
        const refreshResult = await baseQuery(
            "/Login/RefreshToken",
            api,
            extraOptions
        );

        if (refreshResult?.data) {
            // store the new token
            api.dispatch(setCredentials({ ...refreshResult.data }));

            // retry original query with new access token
            result = await baseQuery(args, api, extraOptions);
        } else {
            // navigate("/");
            // if (refreshResult?.error?.status === 403) {
            //fazer metodo logout
            api.dispatch(logOut);
            window.location.href = "https://jrpmanagementbackoffice.pt/";
            // refreshResult.error.data.message = "Your login has expired.";
            // }
            // return refreshResult;
        }
    }

    return result;
};

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 90,
    tagTypes: [
        "Clients",
        "Employee",
        "EmployeeCategories",
        "CostCategoryCategories",
        "SalaryTypes",
        "Users",
        "UserTypes",
        "Vehicles",
        "AccomodationsInWorkPlanningAndNotInEmployeePlanning",
        "VehicleTypes",
        "Works",
        "Equipments",
        "Accomodations",
        "AccomodationCosts",
        "Suppliers",
        "CostCategories",
        "CompanyWorkResumesByMonthResume",
        "PrivateWorkResumesByMonthResume",
        "EmpoyeeCostsByWorkPlanningIdAndDate",
        "CostCategoriesResume",
        "MonthResumes",
        "WorkResumesByClientIdAndMonthResume",
        "Applications",
        "EmpoyeeCostsBySucontractorIdAndDate",
        "CostCategoryFirstTypes",
        "CostCategorySecondTypes",
        "EmployeeWorkHourCostsHistoric",
        "WorksByEmployeeToProcessWorkHourCosts",
        "CompanyDocs",
        "WorkPlannings",
        "VehicleById",
        "EmployeeById",
        "AccomodationPlannings",
        "EmployeePlannings",
        "Reminders",
        "VehiclePlannings",
        "AdvancePayments",
        "EmployeeWorkHourCosts",
        "WorkHourCosts",
        "Contacts",
        "Alerts",
        "WorkPlanningById",
        "WorkById",
        "AccomodationById",
        "Adjustments",
        "VehicleCategories",
        "WorkHours_Employee_FixedSalary",
        "EmployeeWorkHourCostById",
        "EmployeeCostsByWork",
        "AccomodationById",
        "EmployeePlanningsByWorkPlanningId",
        "AccomodationPlanningsByWorkPlanningId",
        "EmployeeId",
        "EmployeePlanningsByEmployeeId",
        "AccomodationPlanningsByAccomodationId",
        "VehiclePlanningsByVehicleId",
        "VehiclePlanningsByWorkId",
        "EmployeePlanningsByWorkId",
        "AccomodationPlanningsByWorkId",
        "EmployeePlanningsByAccomodationId",
        "EmployeeCategoryId",
        "VehiclePlanningsByWorkPlanningId",
        "WorksWithoutWorkPlanning",
        "EmployeeCostsByWorkHistory",
        "WorksWichEmployeeHasProcessedSalaryLastMonth",
        "WorksWithouWorkPlanningAndWithActualWorkInWorkPlanningById",
        "VehiclesNotInWorkPlanning",
        "VehiclesInWorkPlanningAndNotInEmployeePlanning",
        "VehiclesInWorkPlanning",
        "EmployeesNotInWorkPlanning",
        "EmployeesWithoutEmployeeWorkHourCost",
        "EmployeesWithoutEmployeeCostByWork",
        "AccomodationsNotInWorkPlanning",
        "AccomodationsInWorkPlanning",
        "WorkResumeByWorkPlanningId",
        "WorkResumeByClientId",
        "EmpoyeeCostsBySucontractorId",
        "SubContractors",
    ],
    endpoints: (builder) => ({}),
});
