import { apiSlice } from "app/api/apiSlice";

export const contactsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query({
            query: () => ({
                url: `Contacts/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "Contacts", id: "LIST" }],
        }),
    }),
});

export const { useGetContactsQuery } = contactsApiSlice;
