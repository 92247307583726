import { apiSlice } from "app/api/apiSlice";

export const workPlanningsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCompanyWorkResumesByMonthResume: builder.query({
            query: (filterDate) => ({
                url: `WorkResumes/GetAllCompanyWorkResumesByMonthResume/${filterDate.month}/${filterDate.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "CompanyWorkResumesByMonthResume", id: "LIST" },
            ],
        }),
        getAllPrivateWorkResumesByMonthResume: builder.query({
            query: (filterDate) => ({
                url: `WorkResumes/GetAllPrivateWorkResumesByMonthResume/${filterDate.month}/${filterDate.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "PrivateWorkResumesByMonthResume", id: "LIST" },
            ],
        }),
        getAllWorkResumesByClientIdAndMonthResume: builder.query({
            query: (filterDate) => ({
                url: `WorkResumes/GetAllWorkResumesByClientIdAndMonthResume/${filterDate.clientId}/${filterDate.month}/${filterDate.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "WorkResumesByClientIdAndMonthResume", id: "LIST" },
            ],
        }),
        getEmpoyeeCostsByWorkPlanningIdAndDate: builder.query({
            query: (filterData) => ({
                url: `EmployeeCostsByWork/GetEmpoyeeCostsByWorkPlanningIdAndDate/${filterData.workPlanningId}/${filterData.month}/${filterData.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmpoyeeCostsByWorkPlanningIdAndDate", id: "LIST" },
            ],
        }),
        getEmpoyeeCostsBySucontractorIdAndDate: builder.query({
            query: (filterData) => ({
                url: `EmployeeCostsByWork/GetEmpoyeeCostsBySucontractorIdAndDate/${filterData.subcontractorId}/${filterData.month}/${filterData.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmpoyeeCostsBySucontractorIdAndDate", id: "LIST" },
            ],
        }),
        getEmpoyeeCostsBySucontractorId: builder.query({
            query: (subcontractorId) => ({
                url: `EmployeeCostsByWork/GetEmpoyeeCostsBySucontractor/${subcontractorId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmpoyeeCostsBySucontractorId", id: "LIST" },
            ],
        }),
        getCostCategoriesResumeByDate: builder.query({
            query: (filterDate) => ({
                url: `CostCategoriesResume/GetCostCategoriesResumeByDate/${filterDate.month}/${filterDate.year}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "CostCategoriesResume", id: "LIST" }],
        }),
        getMonthResumesByYear: builder.query({
            query: () => ({
                url: `MonthResumes/GetMonthResumesByYear`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "MonthResumes", id: "LIST" }],
        }),
        getWorkResumeByWorkPlanningId: builder.query({
            query: (workPlanningId) => ({
                url: `WorkResumes/GetWorkResumeByWorkPlanningId/${workPlanningId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "WorkResumeByWorkPlanningId", id: "LIST" }],
        }),
        getWorkResumeByClientId: builder.query({
            query: (clientId) => ({
                url: `WorkResumes/GetWorkResumeByClientId/${clientId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "WorkResumeByClientId", id: "LIST" }],
        }),
    }),
});

export const {
    useGetAllCompanyWorkResumesByMonthResumeQuery,
    useLazyGetAllCompanyWorkResumesByMonthResumeQuery,
    useGetAllPrivateWorkResumesByMonthResumeQuery,
    useLazyGetAllPrivateWorkResumesByMonthResumeQuery,
    useGetAllWorkResumesByClientIdAndMonthResumeQuery,
    useLazyGetAllWorkResumesByClientIdAndMonthResumeQuery,
    useGetEmpoyeeCostsByWorkPlanningIdAndDateQuery,
    useLazyGetEmpoyeeCostsByWorkPlanningIdAndDateQuery,
    useGetEmpoyeeCostsBySucontractorIdAndDateQuery,
    useLazyGetEmpoyeeCostsBySucontractorIdAndDateQuery,
    useGetCostCategoriesResumeByDateQuery,
    useLazyGetCostCategoriesResumeByDateQuery,
    useGetMonthResumesByYearQuery,
    useLazyGetMonthResumesByYearQuery,
    useGetWorkResumeByWorkPlanningIdQuery,
    useGetWorkResumeByClientIdQuery,
    useGetEmpoyeeCostsBySucontractorIdQuery,
} = workPlanningsApiSlice;
