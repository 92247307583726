import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import { useGetContactsQuery } from "./contactsApiSlice";
import PageHeader from "components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomWithoutToolbar from "components/DataGridCustomWithoutToolbar";
import LoadingSpinner from "components/LoadingSpinner";
import { useTranslation } from "react-i18next";

const ContactsList = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const { data: contacts, isLoading } = useGetContactsQuery();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
        },
    ];

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoading || !contacts ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("contacts")}
                        subtitle={t("contactsList")}
                    />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile ? "row" : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <DataGrid
                            autoHeight
                            loading={isLoading || !contacts}
                            getRowId={(row) => row.id}
                            rows={contacts || []}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            slots={{ toolbar: DataGridCustomWithoutToolbar }}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ContactsList;
