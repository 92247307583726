import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetSubContractorsQuery } from "./subContractorsApiSlice";
import { useTranslation } from "react-i18next";

const SubContractorsList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const { t } = useTranslation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const { data: subContractors, isLoading } = useGetSubContractorsQuery();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
        },
    ];

    const handleRowClick = (row) => {
        navigate(`${location.pathname}/${row.row.id}`);
    };

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoading || !subContractors ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("subContractors")}
                        subtitle={t("subContractorList")}
                    />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <DataGrid
                            loading={isLoading || !subContractors}
                            getRowId={(row) => row.id}
                            rows={subContractors || []}
                            onRowClick={handleRowClick}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            slots={{ toolbar: DataGridCustomToolbar }}
                            slotProps={{
                                toolbar: { entity: t("newSubcontractor") },
                            }}
                            autoHeight
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default SubContractorsList;
