import React from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import PageHeader from "components/PageHeader";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/LoadingSpinner";
import { useGetAllApplicationsQuery } from "./applicationsApiSlice";
import { SETTINGS } from "helpers/settings";

const ApplicationsList = () => {
    const theme = useTheme();
    const navigate = useNavigate(); /* Set the Navigation Service */
    const location = useLocation();
    const isNonMobile =
        useMediaQuery("(min-width:600px)"); /* Set the Media Query */

    const { t } = useTranslation();

    const { data: applications, isLoading } = useGetAllApplicationsQuery();

    const columns = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "category",
            headerName: t("category"),
            flex: 1,
            valueGetter: (params) => params.row.category.name,
        },
        {
            field: "phoneNumber",
            headerName: t("phoneNumber"),
            flex: 1,
            valueGetter: (params) => params.row.phoneNumber,
        },
        {
            field: "country",
            headerName: t("country"),
            flex: 1,
            valueGetter: (params) => params.row.country,
        },
        {
            field: "status",
            headerName: t("status"),
            flex: 1,
            renderCell: (row) => {
                let color;
                let text;
                if (row.row.applicationStatusId === 2) {
                    color = "green";
                    text = t("accepted");
                } else if (row.row.applicationStatusId === 1) {
                    color = "red";
                    text = t("rejected");
                } else {
                    color = "orange";
                    text = t("pending");
                }
                return (
                    <>
                        <Box
                            sx={{
                                backgroundColor: color,
                                color: "white",
                                borderRadius: "8px",
                                padding: "4px 8px",
                            }}
                        >
                            {text}
                        </Box>
                    </>
                );
            },
        },
    ];

    const columnsMobile = [
        {
            field: "name",
            headerName: t("name"),
            flex: 1,
            valueGetter: (params) => params.row.name,
        },
        {
            field: "category",
            headerName: t("category"),
            flex: 1,
            valueGetter: (params) => params.row.category.name,
        },
        {
            field: "status",
            headerName: t("status"),
            flex: 1,
            renderCell: (row) => {
                let color;
                let text;
                if (
                    row.row.applicationStatusId ===
                    SETTINGS.ApplicationStatus.Accepted
                ) {
                    color = "green";
                    text = t("accepted");
                } else if (
                    row.row.applicationStatusId ===
                    SETTINGS.ApplicationStatus.Rejected
                ) {
                    color = "red";
                    text = t("rejected");
                } else {
                    color = "orange";
                    text = t("pending");
                }
                return (
                    <>
                        <Box
                            sx={{
                                backgroundColor: color,
                                color: "white",
                                borderRadius: "8px",
                                padding: "4px 8px",
                            }}
                        >
                            {text}
                        </Box>
                    </>
                );
            },
        },
    ];

    const handleRowClick = (row) => {
        navigate(`${location.pathname}/${row.row.id}`);
    };

    return (
        <Box m={!isNonMobile ? "1.5rem 1.5rem" : "1.5rem 2.5rem"}>
            {isLoading || !applications ? (
                <LoadingSpinner />
            ) : (
                <>
                    <PageHeader
                        title={t("applications")}
                        subtitle={t("applicationsList")}
                    />
                    <Box
                        mt="40px"
                        minHeight="70vh"
                        display="flex"
                        sx={{
                            "& .MuiDataGrid-root": {
                                border: "none",
                                flexGrow: 1,
                                width: "100%",
                            },
                            "& .MuiDataGrid-cell": {
                                // borderBottom: "none",
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderBottom: "none",
                            },
                            "& .MuiDataGrid-virtualScroller": {
                                backgroundColor: theme.palette.background.A,
                            },
                            "& .MuiDataGrid-footerContainer": {
                                backgroundColor: `${theme.palette.background.J} !important`,
                                color: theme.palette.secondary[100],
                                borderTop: "none",
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                                color: `${theme.palette.background.I}`,
                            },
                            "& .MuiDataGrid-toolbarContainer .MuiBox-root": {
                                display: !isNonMobile ? "flex" : undefined,
                                flexDirection: !isNonMobile
                                    ? "column"
                                    : undefined,
                            },
                            "& .MuiDataGrid-overlay, .MuiDataGrid-virtualScroller":
                                {
                                    backgroundColor: `${theme.palette.background.A} !important`,
                                },
                        }}
                    >
                        <DataGrid
                            autoHeight
                            loading={isLoading || !applications}
                            getRowId={(row) => row.id}
                            onRowClick={handleRowClick}
                            rows={applications || []}
                            columns={!isNonMobile ? columnsMobile : columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            slots={{ toolbar: DataGridCustomToolbar }}
                            slotProps={{
                                toolbar: { entity: t("application") },
                            }}
                        />
                    </Box>
                </>
            )}
        </Box>
    );
};

export default ApplicationsList;
