import { apiSlice } from "app/api/apiSlice";

export const employeeWorkHourCostsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEmployeeWorkHourCostsPaginated: builder.query({
            query: (query) => {
                const params = [];
                params.push(`limit=${(query && query.limit) || 10}`);
                params.push(`offset=${(query && query.offset) || 0}`);
                params.push(
                    `isHistoric=${
                        query && query.isHistoric !== undefined
                            ? query.isHistoric
                            : true
                    }`
                );

                if (query && query.search)
                    params.push(
                        `search=${encodeURIComponent(query && query.search)}`
                    );
                if (query && query.filters)
                    params.push(
                        `filters=${encodeURIComponent(
                            JSON.stringify(query && query.filters)
                        )}`
                    );
                if (query && query.orderByCollection)
                    params.push(
                        `orderByCollection=${encodeURIComponent(
                            JSON.stringify(query && query.orderByCollection)
                        )}`
                    );
                const queryString =
                    params.length > 0 ? `?${params.join("&")}` : "";
                return {
                    url: `EmployeeWorkHourCosts/GetAllPaginated${queryString.toString()}`,
                    validateStatus: (response, result) => {
                        return response.status === 200 && !result.isError;
                    },
                    query,
                };
            },
            providesTags: [{ type: "EmployeeWorkHourCosts", id: "LIST" }],
        }),
        getEmployeeWorkHourCosts: builder.query({
            query: () => ({
                url: `EmployeeWorkHourCosts/GetAll`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [{ type: "EmployeeWorkHourCosts", id: "LIST" }],
        }),
        getEmployeeWorkHourCostsHistoric: builder.query({
            query: () => ({
                url: `EmployeeWorkHourCosts/Historic`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "EmployeeWorkHourCostsHistoric", id: "LIST" },
            ],
        }),
        getEmployeePlanningByWorkPlanningIdAndEmployeeId: builder.query({
            query: (filterData) => ({
                url: `EmployeePlannings/GetEmployeePlanningByWorkPlanningIdAndEmployeeId/${filterData.workPlanningId}/${filterData.employeeId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
        }),
        getEmployeeWorkHourCostsById: builder.query({
            query: (id) => ({
                url: `EmployeeWorkHourCosts/${id}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: (result, error, arg) => [
                { type: "EmployeeWorkHourCosts", id: arg.split("-")[0] },
            ],
        }),
        postEmployeeWorkHourCosts: builder.mutation({
            query: (employeeWorkHourCostsData) => ({
                url: `EmployeeWorkHourCosts`,
                method: "POST",
                body: {
                    ...employeeWorkHourCostsData,
                },
            }),
            invalidatesTags: [
                { type: "EmployeeWorkHourCosts", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
            ],
        }),
        generatePDF: builder.mutation({
            query: (employeeWorkHourCostsData) => ({
                url: `EmployeeWorkHourCosts/generatePDF`,
                method: "POST",
                body: {
                    ...employeeWorkHourCostsData,
                },
            }),
            invalidatesTags: [
                { type: "EmployeeWorkHourCosts", id: "LIST" },
                { type: "EmployeeWorkHourCostsHistoric", id: "LIST" },
                { type: "CompanyDocs", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
            ],
        }),
        getWorksByEmployeeToProcessWorkHourCosts: builder.query({
            query: (employeeId) => ({
                url: `EmployeePlannings/${employeeId}`,
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError;
                },
            }),
            providesTags: [
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        updateEmployeeWorkHourCosts: builder.mutation({
            query: (employeeWorkHourCostData) => ({
                url: `EmployeeWorkHourCosts/Update`,
                method: "PUT",
                body: {
                    ...employeeWorkHourCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                { type: "EmployeeWorkHourCosts", id: arg.id },
            ],
        }),
        addWorkHourCost: builder.mutation({
            query: (workHourCostData) => ({
                url: `EmployeeWorkHourCosts`,
                method: "PUT",
                body: {
                    ...workHourCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: arg.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        addWorkHours_Employee_FixedSalary: builder.mutation({
            query: (workHourCostData) => ({
                url: `EmployeeWorkHourCosts/AddWorkHours_Employee_FixedSalary`,
                method: "PUT",
                body: {
                    ...workHourCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: arg.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        updateWorkHourCost: builder.mutation({
            query: (workHourCostData) => ({
                url: `WorkHourCosts`,
                method: "PUT",
                body: {
                    ...workHourCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: arg.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        updateWorkHours_Employee_FixedSalary: builder.mutation({
            query: (workHourCostData) => ({
                url: `WorkHours_Employee_FixedSalary`,
                method: "PUT",
                body: {
                    ...workHourCostData,
                },
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: arg.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        deleteWorkHourCost: builder.mutation({
            query: (id) => ({
                url: `WorkHourCosts/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: result.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
        deleteEmployeeWorkHourCosts: builder.mutation({
            query: (id) => ({
                url: `EmployeeWorkHourCosts/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: [
                { type: "EmployeeWorkHourCosts", id: "LIST" },
                { type: "EmployeesWithoutEmployeeWorkHourCost", id: "LIST" },
                { type: "EmployeeWorkHourCostsHistoric", id: "LIST" },
            ],
        }),
        deleteWorkHours_Employee_FixedSalaryById: builder.mutation({
            query: (id, employeeWorkHourCostId) => ({
                url: `WorkHours_Employee_FixedSalary/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: (result, error, arg) => [
                {
                    type: "EmployeeWorkHourCosts",
                    id: arg.employeeWorkHourCostId,
                },
                { type: "WorksByEmployeeToProcessWorkHourCosts", id: "LIST" },
            ],
        }),
    }),
});

export const {
    useGetEmployeeWorkHourCostsByIdQuery,
    useGetEmployeeWorkHourCostsQuery,
    useLazyGetEmployeePlanningByWorkPlanningIdAndEmployeeIdQuery,
    useUpdateWorkHours_Employee_FixedSalaryMutation,
    useDeleteWorkHours_Employee_FixedSalaryByIdMutation,
    usePostEmployeeWorkHourCostsMutation,
    useGetWorksByEmployeeToProcessWorkHourCostsQuery,
    useAddWorkHourCostMutation,
    useDeleteEmployeeWorkHourCostsMutation,
    useDeleteWorkHourCostMutation,
    useUpdateWorkHourCostMutation,
    useGeneratePDFMutation,
    useAddWorkHours_Employee_FixedSalaryMutation,
    useGetEmployeeWorkHourCostsHistoricQuery,
    useUpdateEmployeeWorkHourCostsMutation,
} = employeeWorkHourCostsApiSlice;
